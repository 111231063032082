import Axios from "axios";
import { Show, UpsertShow } from "../models/misc";
import { authorizationHeader } from "./apiUtils";

const routes = {
  changeIsActive: "/api/shows/{id}/active",
  shows: "/api/shows",
  updateShow: "/api/shows/{id}",
};

const changeIsActive = async (showId: number, isActive: boolean): Promise<void> => {
  const route = routes.changeIsActive.replace("{id}", showId.toString());
  await Axios.patch(
    route,
    { isActive },
    {
      headers: authorizationHeader(),
    }
  );
};

const createShow = async (show: UpsertShow): Promise<Show> => {
  const result = await Axios.post<Show>(routes.shows, show, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const editShow = async (showId: number, show: UpsertShow): Promise<Show> => {
  const route = routes.updateShow.replace("{id}", showId.toString());
  const result = await Axios.put<Show>(route, show, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const getAll = async (activeOnly = false): Promise<Show[]> => {
  const route = `${routes.shows}?activeonly=${activeOnly}`;

  const result = await Axios.get<Show[]>(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

export { changeIsActive, createShow, editShow, getAll };
