import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { MeleeStone, Product, UpsertMeleeStone } from "../../models/products";
import * as meleeService from "../../services/melee";
import { actions as messageActions } from "./messages";


export type State = Product<MeleeStone>[];



const defaultState: State = [];

const addMelee = Object.assign(
  (model: UpsertMeleeStone) => async (dispatch: Dispatch) => {
    try {
      const result = await meleeService.addMelee(model);
      dispatch(addMelee.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@MELEE/ADD", resolve => (melee: Product<MeleeStone>) => resolve(melee)),
  }
);

const changeMeleeStatus = Object.assign(
  (id: number, isActive: boolean) => async (dispatch: Dispatch) => {
    try {
      await meleeService.changeActiveStatus(id, isActive);
      dispatch(changeMeleeStatus.success({ id, isActive }));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@MELEE/CHANGE_STATUS", resolve => (data: { id: number; isActive: boolean }) =>
      resolve(data)
    ),
  }
);

const editMelee = Object.assign(
  (id: number, model: UpsertMeleeStone) => async (dispatch: Dispatch) => {
    try {
      await meleeService.edit(id, model);
      dispatch(editMelee.success({ id, item: model }));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@MELEE/EDIT", resolve => (data: { id: number; item: UpsertMeleeStone }) =>
      resolve(data)
    ),
  }
);

const fetchMelees = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await meleeService.getAll(false);
      dispatch(fetchMelees.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@MELEE/FETCH", resolve => (melees: Product<MeleeStone>[]) => resolve(melees)),
  }
);

const importCsv = Object.assign(
  (file: File) => async (dispatch: Dispatch) => {
    try {
      await meleeService.importCsv(file);

      messageActions.commit("Import successul. The page will reload to show the updated data", "info")(dispatch);

      setTimeout(() => window.location.reload(), 2000);
    } catch {
      messageActions.commit("Error importing file", "error")(dispatch);
    }
  },
  {}
);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(addMelee.success, (state, action) => state.concat(action.payload)),
  handleAction(changeMeleeStatus.success, (state, action) =>
    produce(state, draft => {
      const item = draft.find(i => i.id === action.payload.id);
      item && (item.isActive = action.payload.isActive);
    })
  ),
  handleAction(editMelee.success, (state, action) =>
    produce(state, draft => {
      const item = state.find(i => i.id === action.payload.id);
      if (!item) {
        return;
      }
      const itemIndex = draft.findIndex(i => i.id === action.payload.id);
      const newItem = { id: item.id, isActive: item.isActive, product: {...action.payload.item, id: item.id, isActive: item.isActive}, ...action.payload.item };
      draft[itemIndex] = newItem
      return draft
    })
  ),
  handleAction(fetchMelees.success, (state, action) => action.payload),
]);

const actions = {
  addMelee,
  changeMeleeStatus,
  editMelee,
  fetchMelees,
  importCsv,
};

export { actions, reducer };
