import Axios from "axios";
import { ContactInformation, ContactUsRequest, Support } from "../models/misc";
import { authorizationHeader } from "./apiUtils";

const routes = {
  contactInformation: "/api/contact",
  contactUs: "/api/contact/send",
  support: "/api/contact/support",
};

const getContactInformation = async (): Promise<ContactInformation> => {
  const result = await Axios.get<ContactInformation>(routes.contactInformation);
  return result.data;
};

const sendContactUsMail = async (request: ContactUsRequest): Promise<number> => {
  const response = await Axios.post(routes.contactUs, request);
  return response.status
};

const updateContactInformation = async (information: ContactInformation): Promise<void> =>
  await Axios.put(routes.contactInformation, information, {
    headers: authorizationHeader(),
  });

const getSupportInfo = async (): Promise<Support> => {
  const result = await Axios.get<Support>(routes.support);
  return result.data;
};

const updateSupportInfo = async (information: Support): Promise<void> =>
  await Axios.put(routes.support, information, {
    headers: authorizationHeader(),
  });

export { getContactInformation, sendContactUsMail, updateContactInformation, getSupportInfo, updateSupportInfo };
