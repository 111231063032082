import { createActionCreator, createReducer } from "deox";
import { IdNameModel } from "../../models/misc";
import * as dataService from "../../services/data";
import { withErrorDispatch } from "./withErrorDispatch";

export type State = {
  countries: IdNameModel[];
};

const fetchCountries = Object.assign(
  () =>
    withErrorDispatch(dataService.getCountries(), result => fetchCountries.success(result), "Error fetching countries"),
  {
    success: createActionCreator("@@ADMIN/DATA/COUNTRIES", resolve => (items: IdNameModel[]) => resolve(items)),
  }
);

const defaultState: State = {
  countries: [],
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchCountries.success, (state, action) => ({ ...state, countries: action.payload })),
]);

const actions = {
  fetchCountries,
};

export { actions, reducer };
