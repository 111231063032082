import React from "react";
import { Spinner } from "reactstrap";

const spinnerContainerStyle: React.CSSProperties = {
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Loading = (): JSX.Element => {
  return (
    <div style={spinnerContainerStyle}>
      <Spinner type="grow" size="large" color="#0e2343" />
    </div>
  );
};

export default Loading;
