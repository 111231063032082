import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Show, UpsertShow } from "../../models/misc";
import * as showsService from "../../services/shows";
import { dataUrlToBase64 } from "../../utils";
import { withErrorDispatch } from "./withErrorDispatch";

export type State = Show[];

const changeIsActive = Object.assign(
  (showId: number, isActive: boolean) =>
    withErrorDispatch(
      showsService.changeIsActive(showId, isActive),
      () => changeIsActive.success({ id: showId, isActive }),
      "Error changing is active status"
    ),
  {
    success: createActionCreator(
      "@@ADMIN/SHOWS/CHANGE_IS_ACTIVE",
      resolve => (show: { id: number; isActive: boolean }) => resolve(show)
    ),
  }
);

const createShow = Object.assign(
  (show: UpsertShow) => {
    if (show.image && show.image.content) {
      show.image.content = dataUrlToBase64(show.image.content);
    }

    return withErrorDispatch(showsService.createShow(show), show => createShow.success(show), "Error creating show");
  },
  {
    success: createActionCreator("@@ADMIN/SHOWS/CREATE", resolve => (show: Show) => resolve(show)),
  }
);

const editShow = Object.assign(
  (showId: number, show: UpsertShow) => {
    if (show.image && show.image.content) {
      show.image.content = dataUrlToBase64(show.image.content);
    }

    return withErrorDispatch(showsService.editShow(showId, show), show => editShow.success(show), "Error editing show");
  },
  {
    success: createActionCreator("@@ADMIN/SHOWS/EDIT", resolve => (show: Show) => resolve(show)),
  }
);

const fetchAllShows = Object.assign(
  () =>
    withErrorDispatch(showsService.getAll(false), result => fetchAllShows.success(result), "Error fetching all shows"),
  {
    success: createActionCreator("@@ADMIN/SHOWS/GET_ALL", resolve => (shows: Show[]) => resolve(shows)),
  }
);

const defaultState: State = [];
const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeIsActive.success, (state, action) =>
    produce(state, draft => {
      const show = draft.find(s => s.id === action.payload.id);
      show && (show.isActive = action.payload.isActive);
    })
  ),
  handleAction(fetchAllShows.success, (state, action) => action.payload),
  handleAction(createShow.success, (state, action) =>
    produce(state, draft => {
      draft.push(action.payload);
    })
  ),
  handleAction(editShow.success, (state, action) => [...state.filter(s => s.id !== action.payload.id), action.payload]),
]);

const actions = {
  changeIsActive,
  createShow,
  editShow,
  fetchAllShows,
};

export { actions, reducer };
