import Axios from "axios";
import { MeleeColor, MeleeStone, Product, UpsertMeleeStone } from "../models/products";
import { authorizationHeader } from "./apiUtils";

const colors = Object.keys(MeleeColor).filter(k => typeof MeleeColor[k as any] === "number");

const routes = {
  changeStatus: "/api/melee/{id}/status",
  melees: "/api/melee",
  import: "/api/melee/import",
  melee: "/api/melee/{id}",
};

const addMelee = async (melee: UpsertMeleeStone): Promise<Product<MeleeStone>> => {
  const result = await Axios.post<Product<MeleeStone>>(routes.melees, melee, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const changeActiveStatus = async (id: number, isActive: boolean): Promise<void> => {
  const route = routes.changeStatus.replace("{id}", id.toString());

  await Axios.patch(
    route,
    { isActive },
    {
      headers: authorizationHeader(),
    }
  );
};

const edit = async (id: number, model: UpsertMeleeStone): Promise<void> => {
  const route = routes.melee.replace("{id}", id.toString());
  await Axios.patch(route, model, {
    headers: authorizationHeader(),
  });
};

const getAll = async (activeOnly: boolean): Promise<Product<MeleeStone>[]> => {
  const route = `${routes.melees}?activeonly=${activeOnly}`;

  const result = await Axios.get<Product<MeleeStone>[]>(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const importCsv = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);

  await Axios.post(routes.import, formData, {
    headers: authorizationHeader(),
  });
};

export { addMelee, changeActiveStatus, edit, getAll, importCsv, colors };
