import Axios from "axios";
import { EmailChannel, EmailTemplate, MemoConfirmEmail, SubscriptionEmail, UpsertEmailTemplate } from "../models/misc";
import { authorizationHeader } from "./apiUtils";

const routes = {
  channel: "/api/emails/channels/{id}",
  channels: "/api/emails/channels",
  sendEmail: "/api/emails/send",
  template: "/api/emails/templates/{id}",
  templates: "/api/emails/templates",
  memoConfirm: "/api/emails/confirmMemo"
};

const createChannel = async (name: string): Promise<EmailChannel> => {
  const result = await Axios.post<EmailChannel>(
    routes.channels,
    { name },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const createTemplate = async (template: UpsertEmailTemplate): Promise<number> => {
  const result = await Axios.post<number>(routes.templates, template, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const deleteTemplate = async (templateId: number): Promise<void> => {
  const route = routes.template.replace("{id}", templateId.toString());
  await Axios.delete(route, {
    headers: authorizationHeader(),
  });
};

const editTemplate = async (templateId: number, template: UpsertEmailTemplate): Promise<void> => {
  const route = routes.template.replace("{id}", templateId.toString());
  await Axios.put(route, template, {
    headers: authorizationHeader(),
  });
};

const getChannels = async (): Promise<EmailChannel[]> => {
  const result = await Axios.get<EmailChannel[]>(routes.channels, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const getTemplates = async (): Promise<EmailTemplate[]> => {
  const result = await Axios.get<EmailTemplate[]>(routes.templates, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const sendEmail = async (email: SubscriptionEmail): Promise<void> => {
  await Axios.post(routes.sendEmail, email, {
    headers: authorizationHeader(),
  });
};

const sendMemoConfirmEmail = async (message : MemoConfirmEmail): Promise<void> =>{
  await Axios.post(routes.memoConfirm, message,{
    headers: authorizationHeader(),
  })
}

export { createChannel, createTemplate, deleteTemplate, editTemplate, getChannels, getTemplates, sendEmail, sendMemoConfirmEmail };
