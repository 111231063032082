import Axios from "axios"
import {Basket} from "../models/basket"
import { authorizationHeader } from "./apiUtils"
const routes ={
  fetchAll: "/api/wishlist/fetch-all"
}

const getAll = async () : Promise<Basket[]> =>{
  const result = await Axios.get<Basket[]>(routes.fetchAll,{
      headers: authorizationHeader(),
  })
  const data = result.data.map(r =>({
    ...r,
    creationDate: new Date(r.creationDate)
  })
  )
  return data
}

export {getAll}