import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { WishListItem } from "../models/cart";
import * as wishListService from "../services/wishList";
import { ApplicationState, store } from "./store";
import { actions as authActions } from "./auth";

export type State = {
  data: WishListItem[] | null;
};

const fetchWishListItems = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { wishList } = getState();

    if (wishList.data && wishList.data.length > 0) {
      return;
    }

    try {
      const data = await wishListService.getWishListItems();
      dispatch(fetchWishListItems.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/SUCCESS", resolve => (wishList: WishListItem[]) => resolve(wishList)),
  }
);

const addDiamondToWishList = Object.assign(
  (itemId: number) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addDiamondToWishList(itemId);
      dispatch(addDiamondToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_DIAMOND/SUCCESS", resolve => (wishListItem: WishListItem) =>
      resolve(wishListItem)
    ),
  }
);

const addDiamondsFromSetToWishList = Object.assign(
  (itemIds: number[]) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addDiamondsFromSetToWishList(itemIds);
      dispatch(addDiamondsFromSetToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_DIAMONDS_SET/SUCCESS", resolve => (wishListItem: WishListItem[]) =>
      resolve(wishListItem)
    ),
  }
);

const addMeleeToWishList = Object.assign(
  (itemId: number, numberOfStones?: number, carats?: number) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addMeleeToWishList(itemId, numberOfStones, carats);
      dispatch(addDiamondToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_DIAMOND/SUCCESS", resolve => (wishListItem: WishListItem) =>
      resolve(wishListItem)
    ),
  }
);

const addNameDiamondsToWishList = Object.assign(
  (itemIds: number[]) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addNameDiamondsToWishList(itemIds);
      dispatch(addNameDiamondsToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_NAME_DIAMOND/SUCCESS", resolve => (wishListItems: WishListItem[]) =>
      resolve(wishListItems.map(obj => ({ ...obj, itemType: 3 })))
    ),
  }
);

const addParcelToWishList = Object.assign(
  (itemId: number, numberOfStones?: number, ringSize?: number) => async (dispatch: Dispatch) => {
    try {
      const data = await wishListService.addParcelToWishList(itemId, numberOfStones, ringSize);
      dispatch(addDiamondToWishList.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/ADD_DIAMOND/SUCCESS", resolve => (wishListItem: WishListItem) =>
      resolve(wishListItem)
    ),
  }
);

const bulkAddDiamondsToWishList = Object.assign(
  (itemIds: number[]) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    try {
      const data = await wishListService.bulkAddDiamondsToCart(itemIds);
      const { wishList } = getState();
      const wishListItemId = (wishList.data || []).map(ci => ci.itemId);
      const newItems = data.filter(ci => !wishListItemId.includes(ci.itemId));

      dispatch(bulkAddDiamondsToWishList.success(newItems));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/BULK_ADD_DIAMOND/SUCCESS", resolve => (wishListItems: WishListItem[]) =>
      resolve(wishListItems)
    ),
  }
);
const bulkRemoveDiamondsFromBasket = Object.assign(
  (wishedIds: number[]) => async (dispatch: Dispatch) => {
    try {
      await wishListService.bulkRemoveDiamondsFromBasket(wishedIds);
      dispatch(bulkRemoveDiamondsFromBasket.success(wishedIds));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@WISHLIST/BULK_REMOVE_DIAMONDS/SUCCESS", resolve => (wishListItemIds: number[]) =>
      resolve(wishListItemIds)
    ),
  }
);

const removeFromWishList = Object.assign(
  (itemId: number | number[]) => async (dispatch: Dispatch) => {
    try {
      if (Array.isArray(itemId)) {
        //itemId is an Array of wishedIds
        const wishedItems = store.getState().wishList.data;
        if (!wishedItems) return;
        const wishedItemsIds = wishedItems.filter(item => itemId.includes(item.id)).map(w => w.itemId);
        const allDiamondsFromSetIds = store
          .getState()
          .diamonds.all?.filter(d => !!d.product.lineSet)
          .map(d => d.id);
        const diamondPartOfSet = allDiamondsFromSetIds?.filter(id => wishedItemsIds.includes(id));

        //logic to remove set items from store
        if (diamondPartOfSet && allDiamondsFromSetIds) {
          const wishedItemsFromSetIds = wishedItems
            .filter(w => allDiamondsFromSetIds.includes(w.itemId))
            .map(w => w.id);
          return dispatch(removeFromWishList.success([...itemId, ...wishedItemsFromSetIds]));
        }

        return dispatch(removeFromWishList.success(itemId as number[]));
      }

      await wishListService.deleteWishListItem(itemId as number);
      dispatch(removeFromWishList.success(itemId as number));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info("Could not remove: ", e);
    }
  },
  {
    success: createActionCreator("@@WISHLIST/REMOVE/SUCCESS", resolve => (itemId: number | number[]) =>
      resolve(itemId)
    ),
  }
);

const deleteDiamondsSetFromBasket = Object.assign(
  (itemId: number | number[]) => async (dispatch: Dispatch) => {
    try {
      await wishListService.deleteDiamondsSetFromBasket(itemId as number);
      const allDiamonds = store.getState().diamonds.all;
      if (!allDiamonds) return;
      const setName = allDiamonds.find(diamond => diamond.id === itemId)?.product.lineSet;
      const findAllDiamondsFromSetIds = allDiamonds
        .filter(diamond => diamond.product.lineSet === setName)
        .map(d => d.id);
      dispatch(deleteDiamondsSetFromBasket.success(findAllDiamondsFromSetIds as number[]));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info("Could not delete the set: ", e);
    }
  },
  {
    success: createActionCreator("@@WISHLIST/REMOVEDIAMONDSSET/SUCCESS", resolve => (itemId: number[]) =>
      resolve(itemId)
    ),
  }
);
const removeDiamondFromBasketPage = Object.assign(
  (wishedItemId: number) => async (dispatch: Dispatch) => {
    try {
      await wishListService.deleteWishListItemFromBasket(wishedItemId as number);
      dispatch(removeDiamondFromBasketPage.success(wishedItemId as number));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info("Could not remove: ", e);
    }
  },
  {
    success: createActionCreator("@@WISHLIST/REMOVE_FROM_BASKET/SUCCESS", resolve => (itemId: number | number[]) =>
      resolve(itemId)
    ),
  }
);

const defaultState: State = { data: null };

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchWishListItems.success, (state, action) =>
    produce(state, draft => {
      draft.data = action.payload;
    })
  ),
  handleAction([addDiamondToWishList.success, addNameDiamondsToWishList.success, bulkAddDiamondsToWishList.success], (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).concat(action.payload);
    })
  ),
  handleAction(addDiamondsFromSetToWishList.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).concat(action.payload);
    })
  ),
  handleAction(removeFromWishList.success, (state, action) =>
    produce(state, draft => {
      if (Array.isArray(action.payload)) {
        const itemIds = action.payload as number[];
        draft.data = (state.data || []).filter(i => !itemIds.some(id => id === i.id));
        return;
      }
      draft.data = (state.data || []).filter(i => i.itemId !== action.payload);
    })
  ),
  handleAction(removeDiamondFromBasketPage.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).filter(i => i.id !== action.payload);
    })
  ),
  handleAction(bulkRemoveDiamondsFromBasket.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).filter(i => !action.payload.includes(i.id));
    })
  ),
  handleAction(deleteDiamondsSetFromBasket.success, (state, action) =>
    produce(state, draft => {
      const remainingDiamonds = (state.data || []).filter(i => !action.payload.includes(i.itemId));
      draft.data = remainingDiamonds;
    })
  ),

  handleAction(authActions.logout.success, (state, action) =>
    produce(state, draft => {
      draft.data = [];
    })
  ),
]);

const actions = {
  addDiamondToWishList,
  addDiamondsFromSetToWishList,
  addNameDiamondsToWishList,
  addMeleeToWishList,
  addParcelToWishList,
  bulkAddDiamondsToWishList,
  bulkRemoveDiamondsFromBasket,
  fetchWishListItems,
  removeFromWishList,
  removeDiamondFromBasketPage,
  deleteDiamondsSetFromBasket,
};

export { actions, reducer };
