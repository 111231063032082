import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { v4 as uuid } from "uuid";

type MessageSeverity = "error" | "info";
type AdminMessage = {
  id: string;
  message: string;
  severity: MessageSeverity;
};

export type State = AdminMessage[];

const defaultState: State = [];

const acknowledge = Object.assign(
  (id: string) => (dispatch: Dispatch) => {
    dispatch(acknowledge.success(id));
  },
  {
    success: createActionCreator("@@ADMIN/ERRORS/ACKNOWLEDGE", resolve => (id: string) => resolve(id)),
  }
);

const commit = Object.assign(
  (message: string, severity: MessageSeverity) => (dispatch: Dispatch) => {
    dispatch(commit.success({ message, severity }));
  },
  {
    success: createActionCreator(
      "@@ADMIN/ERRORS/DISPATCH",
      resolve => (message: { message: string; severity: MessageSeverity }) => resolve(message)
    ),
  }
);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(acknowledge.success, (state, action) =>
    produce(state, draft => {
      draft.splice(
        draft.findIndex(item => item.id === action.payload),
        1
      );
    })
  ),
  handleAction(commit.success, (state, action) =>
    produce(state, draft => {
      draft.push({ message: action.payload.message, id: uuid(), severity: action.payload.severity });
    })
  ),
]);

const actions = {
  acknowledge,
  commit,
};

export { actions, reducer };
