import { Filter } from "./hooks/useFilters";
import { Color } from "./models/misc";
import { Diamond } from "./models/products";
import papa from "papaparse";
import { actions as messageActions } from "./store/admin/messages";
import { Dispatch } from "redux";
import ReactGa from "react-ga";
import { PageId } from "./store/UIState";
import { CartItem } from "./models/cart";
import { State as WishListState } from "./store/wishList";

import { State as DataState } from "./store/data";
import { getDiamondColor } from "./models/utils";
import { State } from "./store/misc";

type Delimiter = "," | ";";

type CategoryGA = "Individual Diamond" | "Diamond Search";
type ActionGA =
  | "Diamond Details"
  | "Fancy Diamond Shape"
  | "Black Diamond Shape"
  | "White Diamond Shape"
  | "Fancy Diamond Color"
  | "Black Diamond Color"
  | "White Diamond Color";
type PageIdList = {
  white: ActionGA;
  fancy: ActionGA;
  black: ActionGA;
};
type EventTracking = {
  category: CategoryGA;
  action: string;
  label: string;
};
type FilterKeysToStoreFiltersType = {
  colorId: string;
  clarityId: string;
  cutId: string;
  fancyIntensity: string;
  fluorId: string;
  labId: string;
  polishId: string;
  shapeId: string;
  symmetryId: string;
  carats: string;
  pricePerCarat: string;
  stockId: string;
  length: string;
  width: string;
  totalPrice: string;
};
const filterKeysToStoreFilters: FilterKeysToStoreFiltersType = {
  colorId: "colors",
  clarityId: "clarities",
  cutId: "cuts",
  fancyIntensity: "fancyIntensities",
  fluorId: "fluors",
  labId: "labs",
  polishId: "polishes",
  shapeId: "shapes",
  symmetryId: "symmetries",
  carats: "carats",
  pricePerCarat: "PPC",
  stockId: "stockId",
  length: "length",
  width: "width",
  totalPrice: "total$",
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const pipe = <R>(fn1: (a: R) => R, ...fns: Array<(a: R) => R>) =>
  fns.reduce((prevFn, nextFn) => value => nextFn(prevFn(value)), fn1);

const dataUrlToBase64 = (dataUrl: string): string => dataUrl.replace(/^data:image\/(\w*);base64,/, "");

const removeEmptyStrings = (input: unknown): unknown => {
  const inputIndexable = input as { [index: string]: any };

  const result = Object.keys(inputIndexable).reduce((obj, key) => {
    const property = inputIndexable[key];
    if (typeof property === "object") {
      obj[key] = removeEmptyStrings(property);
    }

    obj[key] = property === "" ? null : property;
    return obj;
  }, {} as { [index: string]: unknown });

  return result;
};

const convertToLocale = (price: number | undefined): string | undefined => {
  if (!price) {
    return;
  }

  return price.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

type Overtones = {
  [key: string]: number;
};

const filterOvertoneOrIntensity = (data: unknown, filter: Filter<unknown>, colors: Color[]): boolean => {
  const filterValue = filter.value as number[];

  // Diamonds filtering by overtone
  if (filter.key === "colorId") {
    let overtones: Overtones = {
      BROWN: colors.find(color => color.name === "Brown")!.id,
      ORANG: colors.find(color => color.name === "Orange")!.id,
      GRAY: colors.find(color => color.name === "Gray")!.id,
      PURPL: colors.find(color => color.name === "Purple")!.id,
      YELLOW: colors.find(color => color.name === "Yellow")!.id,
      BLU: colors.find(color => color.name === "Blue")!.id,
      GREEN: colors.find(color => color.name === "Green")!.id,
      PINK: colors.find(color => color.name === "Pink")!.id,
      RED: colors.find(color => color.name === "Red")!.id,
      VIOLET: colors.find(color => color.name === "Violet")!.id,
    };

    const checkForOvertone = (filterValue: number[], diamond: Diamond) => {
      return filterValue.some(value => {
        const findOvertoneColor = Object.keys(overtones).find(key => overtones[key] === value) as string;
        const checkWord = diamond.overtone && findOvertoneColor ? diamond.overtone.search(findOvertoneColor) : -1;
        const ifTrue = checkWord !== -1 || diamond.colorId === value ? true : false;
        return ifTrue;
      });
    };
    return checkForOvertone(filterValue, data as Diamond);
  }
  // Diamonds filtering by fancyIntensity
  if (filter.key === "fancyIntensity") {
    let intensities: Overtones = {
      Faint: colors.find(color => color.name === "Faint")!.id,
      Very_Light: colors.find(color => color.name === "Very Light")!.id,
      Light: colors.find(color => color.name === "Light")!.id,
      Fancy_Light: colors.find(color => color.name === "Fancy Light")!.id,
      Fancy: colors.find(color => color.name === "Fancy")!.id,
      Fancy_Intense: colors.find(color => color.name === "Fancy Intense")!.id,
      Fancy_Vivid: colors.find(color => color.name === "Fancy Vivid")!.id,
      Fancy_Deep: colors.find(color => color.name === "Fancy Deep")!.id,
      Fancy_Dark: colors.find(color => color.name === "Fancy Dark")!.id,
    };
    const checkForIntensities = (filterValue: number[], diamond: Diamond) => {
      return filterValue.some(value => {
        const findIntensity = Object.keys(intensities)
          .find(key => intensities[key] === value)
          ?.toUpperCase()
          .replace("_", " ") as string;
        const checkWord =
          diamond.fancyIntensity && diamond.fancyIntensity.toLowerCase() === findIntensity.toLowerCase() ? true : false;
        return checkWord;
      });
    };
    return checkForIntensities(filterValue, data as Diamond);
  }
  return false;
};

const validateDiamondsCsvRows = async (csvFile: File, dispatch: Dispatch<any>): Promise<boolean> => {
  //parsing the CSV to array of objects , validating not nullable fields and return boolean
  const parseFile = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      papa.parse(csvFile, {
        header: true,
        complete: function (result) {
          const diamondsArray = result.data;
          diamondsArray.forEach((diamond: any, i) => {
            if (i === diamondsArray.length - 1) {
              resolve(true);
              return;
            }
            if (!diamond["Stock #"]) {
              messageActions.commit(
                `Upload Aborted. Diamond number: ${i + 2} requires a value for "Stock #" field.`,
                "error"
              )(dispatch);
              resolve(false);
              return;
            }
            if (!diamond.Weight) {
              messageActions.commit(
                `Upload Aborted. Diamond number: ${i + 2} requires a value for "Weight" field.`,
                "error"
              )(dispatch);
              resolve(false);
              return;
            }
            if (!diamond["Color"] && !diamond["Fancy Color"]) {
              messageActions.commit(
                `Upload Aborted. Diamond number: ${i + 2} requires a value for "Color" or "Fancy Color" field.`,
                "error"
              )(dispatch);
              resolve(false);
              return;
            }
            if (!diamond.Lab) {
              messageActions.commit(
                `Upload Aborted. Diamond number: ${i + 2} requires a value for "Lab" field.`,
                "error"
              )(dispatch);
              resolve(false);
              return;
            }
            if (!diamond.Lab) {
              messageActions.commit(
                `Upload Aborted. Diamond number: ${i + 2} requires a value for "Lab" field.`,
                "error"
              )(dispatch);
              resolve(false);
              return;
            }
            if (!diamond["Rapnet Price"]) {
              messageActions.commit(
                `Upload Aborted. Diamond number: ${i + 2} requires a value for "Rapnet Price" field.`,
                "error"
              )(dispatch);
              resolve(false);
              return;
            }
            if (!diamond.Shape) {
              messageActions.commit(
                `Upload Aborted. Diamond number: ${i + 2} requires a value for "Shape" field.`,
                "error"
              )(dispatch);
              resolve(false);
              return;
            }
          });
        },
      });
    });
  };
  const parseData = (await parseFile()) as boolean;
  return parseData;
};

const filterNameAndValueData = (filtersArray: Filter<any>[], data: DataState, diamonds: Diamond[], pageId: PageId) => {
  try {
    let stringToReturn = ``;
    filtersArray
      .map(f => {
        const filterKey = f.key as keyof FilterKeysToStoreFiltersType;
        const filterName: keyof DataState = filterKeysToStoreFilters[filterKey];
        // Color Type is most suitable for the data info
        const filterData: Color[] = data[filterName];
        stringToReturn += ` ${filterName.toUpperCase()}: `;
        if (!Array.isArray(f.value) && f.type === "equals") {
          stringToReturn += `${f.value}, `;

          return null;
        }
        if (f.type === "moreOrLess") {
          stringToReturn += `between- ${f.value}`;
          return stringToReturn.replace(/.$/, "");
        }
        if (
          (f.key === "carats" ||
            f.key === "totalPrice" ||
            f.key === "pricePerCarat" ||
            f.key === "length" ||
            f.key === "width") &&
          filtersArray.some(f => f.type === "lessThan")
        ) {
          stringToReturn += `${f.type}- ${f.value},`;
          return stringToReturn.replace(/.$/, "");
        }
        // For Values with f.value === Array of ids
        f.value.forEach((v: number) => {
          if (pageId !== "fancy" && f.key === "colorId") {
            stringToReturn += `${filterData.find(data => data.id === v)?.variation}, `;
            return stringToReturn.replace(/.$/, "");
          }
          stringToReturn += `${filterData.find(data => data.id === v)?.name}, `;
        });
        return stringToReturn.replace(/.$/, "");
      })
      .join(";");

    const diamondsInfoToString = diamonds
      .slice(0, 8)
      .map(diamond => {
        const diamondColor = getDiamondColor(diamond);
        return `{{  ${diamond.stockId} | Shape: ${diamond.shape} | Weight: ${diamond.carats}ct | Color: ${diamondColor} | Clarity: ${diamond.clarity}  }}`;
      })
      .join(" <> ");

    return {
      diamondsInfo: diamondsInfoToString,
      filterInfo: stringToReturn,
    };
  } catch {
    console.log("filterNameAndValue Error");
    return {
      diamondsInfo: "",
      filterInfo: "",
    };
  }
};

//Tracking on click events for Google analytics
const eventTrackingGA = ({ category, action, label }: EventTracking) => {
  ReactGa.event({
    category: category,
    action: action,
    label: label,
  });
};
const filterDataTrackingGa = (pageId: Partial<PageId>, action: string, label: string) => {
  const pageIdCapitalFirstLetter = pageId.charAt(0).toUpperCase() + pageId.slice(1);

  return eventTrackingGA({
    category: "Diamond Search",
    action: `Page: ${pageIdCapitalFirstLetter} <> ${action}`,
    label,
  });
};

const previousPageLogic = (location: any) => {
  if (!location.state) {
    return;
  }
  if (location.state.length > 0 && location.state === "memo") {
    return "";
  }
  if (location.state === "fancyShapeLines") {
    return "fancy-shape-lines";
  }
  return location.state && location.state.length > 0
    ? location.state === "matchingPairs"
      ? "matching-pairs"
      : location.state === "cart"
      ? `${location.state}`
      : `${location.state}-diamonds`
    : "";
};

const tableRowTemplate = (diamond: Diamond, index: number = 0): string => {
  const memoPPC = parseInt(convertToLocale(diamond.memoPricePerCarat) as string).toFixed(2);
  const memoTotal = parseInt(convertToLocale(diamond.memoTotalPrice) as string).toFixed(2);
  return `<tr><td>${index + 1}</td><td style="padding: 0 4px">${diamond.stockId}</td><td style="padding: 0 4px">${
    diamond.parcelStones
  }</td><td style="padding: 0 4px">${diamond.carats}</td><td style="padding: 0 4px">${
    diamond.shape
  }</td style="padding: 0 4px"><td style="padding: 0 4px">${
    diamond.color === "NA" ? "" : diamond.color
  }</td><td style="padding: 0 4px">${diamond.clarity === "NA" ? "" : diamond.clarity}</td><td style="padding: 0 4px">${
    diamond.cut ? diamond.cut : ""
  }</td><td style="padding: 0 4px">${diamond.polish ? diamond.polish : ""}</td><td style="padding: 0 4px">${
    diamond.symmetry ? diamond.symmetry : ""
  }</td><td style="padding: 0 4px">${diamond.fluor ? diamond.fluor : ""}</td><td style="padding: 0 4px">${
    diamond.depthPercent
  }</td><td style="padding: 0 4px">${diamond.tablePercent}</td><td style="padding: 0 4px">${diamond.length}-${
    diamond.width
  }x${
    diamond.height
  }</td><td style="padding: 0 4px">${memoPPC}</td><td style="padding: 0 4px">${memoTotal}</td><td style="padding: 0 4px">${diamond.discount?.toFixed(
    2
  )}</td><td style="padding: 0 4px"><a href=${diamond.certificateUrl}>${
    diamond.lab
  }</a></td><td style="padding: 0 4px"><a href=${diamond.image3d}>link</a></td><td style="padding: 0 4px"><a href=${
    diamond.imageUrl
  }>link</a></td></tr>`;
};

const checkCartItemsInBasket = ({
  cartItems,
  diamonds,
  removeItemFormCart,
}: {
  cartItems: any;
  diamonds: Diamond[];
  removeItemFormCart: (id: number) => () => Promise<void>;
}) => {
  if (cartItems && diamonds.length > 0) {
    cartItems.forEach((c: CartItem) => {
      const isInBasket = diamonds.some(d => d.wishListId === c.wishedItemId);
      if (!isInBasket) {
        removeItemFormCart(c.wishedItemId as number)();
      }
    });
  }
};

const meleeData = ({ diamond, wishList }: { diamond: Diamond; wishList: WishListState }) => {
  if (!diamond.isMelee) {
    return {
      meleeFromWishList: diamond,
      meleeQuantity: 0,
      meleeTotalCarats: 0,
    };
  }
  const meleeFromWishList = wishList.data?.find(c => c.itemId === diamond.id);
  const meleeQuantity = meleeFromWishList?.quantity;
  const meleeTotalCarats = meleeQuantity ? meleeQuantity * diamond.carats : diamond.carats;

  return { meleeFromWishList, meleeQuantity, meleeTotalCarats };
};

const truncateText = (text: string, windowsWidth?: number) => {
  if (text.length > 10 && windowsWidth && windowsWidth < 1600) {
    return `${text.substring(0, 9)}...`;
  }
  if (text.length > 14 && !windowsWidth) {
    return `${text.substring(0, 13)}...`;
  }

  return text;
};

const isDiamondCheckedFunc = (diamondsFromSet: number[], misc: State | any) => {
  return diamondsFromSet.filter(itemId => !misc?.includes(itemId));
};

export {
  isDiamondCheckedFunc,
  filterNameAndValueData,
  dataUrlToBase64,
  pipe,
  removeEmptyStrings,
  convertToLocale,
  filterOvertoneOrIntensity,
  validateDiamondsCsvRows,
  eventTrackingGA,
  filterDataTrackingGa,
  previousPageLogic,
  tableRowTemplate,
  checkCartItemsInBasket,
  meleeData,
  truncateText,
};
