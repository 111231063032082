import { createActionCreator, createReducer } from "deox";
import { AboutUsConfiguration, ContactInformation, Support, UpsertAboutUsConfiguration } from "../../models/misc";
import * as contactService from "../../services/contact";
import * as siteSettingsService from "../../services/siteSettings";
import { dataUrlToBase64 } from "../../utils";
import { withErrorDispatch } from "./withErrorDispatch";

export type State = {
  aboutUs: AboutUsConfiguration | null;
  contactInformation: ContactInformation | null;
  maintenance: boolean | null;
  maintenanceMessage: string | null;
  support: Support | null;
  vendors: string[] | null;
  allVendors: string[] | null;
};

const fetchAboutUsConfiguration = Object.assign(
  () =>
    withErrorDispatch(
      siteSettingsService.getAboutUsConfiguration(),
      result => fetchAboutUsConfiguration.success(result),
      "Error fetching about us configuration"
    ),
  {
    success: createActionCreator("@@ADMIN/ABOUT_US/FETCH", resolve => (item: AboutUsConfiguration) => resolve(item)),
  }
);

const fetchVendors = Object.assign(
  () =>
    withErrorDispatch(
      siteSettingsService.getVendors(),
      result => fetchVendors.success(result),
      "Error fetching vendors"
    ),
  {
    success: createActionCreator("@@ADMIN/VENDORS/FETCH", resolve => (item: string[]) => resolve(item)),
  }
);

const fetchSiteVendors = Object.assign(
  () =>
    withErrorDispatch(
      siteSettingsService.getSiteVendors(),
      result => fetchSiteVendors.success(result),
      "Error fetching site vendors"
    ),
  {
    success: createActionCreator("@@ADMIN/SITE_VENDORS/FETCH", resolve => (item: string[]) => resolve(item)),
  }
);

const addVendor = Object.assign(
  (vendor: string) =>
    withErrorDispatch(
      siteSettingsService.addVendor(vendor),
      () => addVendor.success(vendor),
      "Error adding vendor"
    ),
  {
    success: createActionCreator("@@ADMIN/VENDORS/ADD", resolve => (item: string) => resolve(item)),
  }
);

const deleteVendor = Object.assign(
  (vendor: string) =>
    withErrorDispatch(
      siteSettingsService.deleteVendor(vendor),
      () => deleteVendor.success(vendor),
      "Error deleting vendor"
    ),
  {
    success: createActionCreator("@@ADMIN/VENDORS/DELETE", resolve => (item: string) => resolve(item)),
  }
);

const fetchContactInformation = Object.assign(
  () =>
    withErrorDispatch(
      contactService.getContactInformation(),
      result => fetchContactInformation.success(result),
      "Error fetching contact information"
    ),
  {
    success: createActionCreator("@@ADMIN/CONTACT/FETCH", resolve => (item: ContactInformation) => resolve(item)),
  }
);

const fetchMaintenance = Object.assign(
  () =>
    withErrorDispatch(
      siteSettingsService.getMaintenance(),
      result => fetchMaintenance.success(result),
      "Error fetching maintenanance information"
    ),
  {
    success: createActionCreator("@@ADMIN/MAINTENANCE/FETCH", resolve => (item: boolean) => resolve(item)),
  }
);

const fetchMaintenanceMessage = Object.assign(
  () =>
    withErrorDispatch(
      siteSettingsService.getMaintenanceMessage(),
      result => fetchMaintenanceMessage.success(result),
      "Error fetching maintenanance information"
    ),
  {
    success: createActionCreator("@@ADMIN/MAINTENANCE_MESSAGE/FETCH", resolve => (item: string) => resolve(item)),
  }
);

const setMaintenance = Object.assign(
  (isEnabled: boolean) =>
    withErrorDispatch(
      siteSettingsService.setMaintenance(isEnabled),
      () => setMaintenance.success(isEnabled),
      "Error updating maintenance information"
    ),
  {
    success: createActionCreator("@@ADMIN/MAINTENANCE/SET", resolve => (item: boolean) => resolve(item)),
  }
);

const setMaintenanceMessage = Object.assign(
  (message: string) =>
    withErrorDispatch(
      siteSettingsService.setMaintenanceMessage(message),
      () => setMaintenanceMessage.success(message),
      "Error updating maintenance information"
    ),
  {
    success: createActionCreator("@@ADMIN/MAINTENANCE_MESSAGE/SET", resolve => (item: string) => resolve(item)),
  }
);

const updateAboutUsConfiguration = Object.assign(
  (settings: UpsertAboutUsConfiguration) => {
    settings.image1?.content && (settings.image1.content = dataUrlToBase64(settings.image1.content));
    settings.image2?.content && (settings.image2.content = dataUrlToBase64(settings.image2.content));

    return withErrorDispatch(
      siteSettingsService.updateAboutUsConfiguration(settings),
      result => updateAboutUsConfiguration.success(result),
      "Error saving about us configuration"
    );
  },
  {
    success: createActionCreator("@@ADMIN/ABOUT_US/UPDATE", resolve => (item: AboutUsConfiguration) => resolve(item)),
  }
);

const updateContactInformation = Object.assign(
  (information: ContactInformation) =>
    withErrorDispatch(
      contactService.updateContactInformation(information),
      () => updateContactInformation.success(information),
      "Error updating contact information"
    ),
  {
    success: createActionCreator("@@ADMIN/CONTACT/UPDATE", resolve => (item: ContactInformation) => resolve(item)),
  }
);

const fetchSupportInformation = Object.assign(
  () =>
    withErrorDispatch(
      contactService.getSupportInfo(),
      result => fetchSupportInformation.success(result),
      "Error fetching support contacts"
    ),
  {
    success: createActionCreator("@@ADMIN/SUPPORT/FETCH", resolve => (item: Support) => resolve(item)),
  }
);

const updateSupportInformation = Object.assign(
  (information: Support) =>
    withErrorDispatch(
      contactService.updateSupportInfo(information),
      () => updateSupportInformation.success(information),
      "Error updating support contacts"
    ),
  {
    success: createActionCreator("@@ADMIN/SUPPORT/UPDATE", resolve => (information: Support) => resolve(information)),
  }
);

const defaultState: State = {
  aboutUs: null,
  contactInformation: null,
  maintenance: false,
  maintenanceMessage: null,
  support: null,
  vendors: [],
  allVendors: [],
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction([fetchAboutUsConfiguration.success, updateAboutUsConfiguration.success], (state, action) => ({
    ...state,
    aboutUs: action.payload,
  })),
  handleAction([fetchContactInformation.success, updateContactInformation.success], (state, action) => ({
    ...state,
    contactInformation: action.payload,
  })),
  handleAction([fetchMaintenance.success, setMaintenance.success], (state, action) => ({
    ...state,
    maintenance: action.payload,
  })),
  handleAction([fetchMaintenanceMessage.success, setMaintenanceMessage.success], (state, action) => ({
    ...state,
    maintenanceMessage: action.payload,
  })),
  handleAction([fetchSupportInformation.success, updateSupportInformation.success], (state, action) => ({
    ...state,
    support: action.payload,
  })),
  handleAction(fetchVendors.success, (state, action) => ({
    ...state,
    allVendors: action.payload,
  })),
  handleAction(addVendor.success, (state, action) => ({
    ...state,
    vendors: [...(state.vendors || []), action.payload],
  })),
  handleAction(deleteVendor.success, (state, action) => ({
    ...state,
    vendors: (state.vendors || []).filter(v => v !== action.payload),
  })),
  handleAction(fetchSiteVendors.success, (state, action) => ({
    ...state,
    vendors: action.payload,
  })),
]);

const actions = {
  fetchAboutUsConfiguration,
  fetchContactInformation,
  fetchMaintenance,
  fetchMaintenanceMessage,
  setMaintenance,
  setMaintenanceMessage,
  updateAboutUsConfiguration,
  updateContactInformation,
  updateSupportInformation,
  fetchSupportInformation,
  fetchVendors,
  addVendor,
  deleteVendor,
  fetchSiteVendors,
};

export { actions, reducer };
