import { createReducer } from "deox";
import * as auth from "../store/auth";

export type State = {
  login: string | null;
  register: string | null;
};

const defaultState: State = {
  login: null,
  register: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(auth.actions.register.error, (state, action) => ({ ...state, register: action.payload })),
  handleAction(auth.actions.register.success, state => ({ ...state, register: null })),
  handleAction(auth.actions.login.error, (state, action) => ({ ...state, login: action.payload })),
  handleAction(auth.actions.login.success, state => ({ ...state, login: null })),
]);

export { reducer };
