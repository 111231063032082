import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "bootstrap/dist/css/bootstrap.min.css";
import { ConnectedRouter } from "connected-react-router";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import history from "./history";
import registerServiceWorker from "./registerServiceWorker";
import Router from "./router";
import { store } from "./store/store";
import ReactGa from "react-ga";

// workaround for having the style.css after bootstrap as the auto sorting messes it up
require("./css/style.css");
//for production
ReactGa.initialize("UA-61571979-3");
//for dev testing
// ReactGa.initialize("UA-193626086-1")
ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router />
      </ConnectedRouter>
    </Provider>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);

registerServiceWorker();
