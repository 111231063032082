import Axios, { AxiosResponse } from "axios";
import { DiamondStatusEnum, DiamondsType, Product, UpsertDiamondModel } from "../models/products";
import { removeEmptyStrings } from "../utils";
import { authorizationHeader } from "./apiUtils";

const routes = {
  diamonds: "/api/diamonds",
  diamondsAdmin: "api/diamonds/admin",
  diamond: "/api/diamonds/{id}",
  diamondActiveStatus: "/api/diamonds/{id}/active",
  diamondStatus: "/api/diamonds/{id}/status",
  getHighlighted: "/api/diamonds/highlighted",
  highlight: "/api/diamonds/{id}/highlight",
  import: "/api/diamonds/import",
  importAdditional: "/api/diamonds/importAdditional"
};

const addDiamond = async (diamond: UpsertDiamondModel): Promise<Product<DiamondsType>> => {
  const data = removeEmptyStrings(diamond);

  const result = await Axios.post<Product<DiamondsType>>(routes.diamonds, data, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const changeActiveStatus = async (diamondId: number, isActive: boolean): Promise<void> => {
  const route = routes.diamondActiveStatus.replace("{id}", diamondId.toString());
  await Axios.patch(
    route,
    { isActive },
    {
      headers: authorizationHeader(),
    }
  );
};

const changeStatus = async (diamondId: number, status: DiamondStatusEnum): Promise<AxiosResponse> => {
  const route = routes.diamondStatus.replace("{id}", diamondId.toString());
  const response = await Axios.patch(
    route,
    { status },
    {
      headers: authorizationHeader(),
    }
  ).then(resp => resp);
  return response;
};

const getAllDiamonds = async (availableOnly: boolean): Promise<Product<DiamondsType>[]> => {
  const route = `${routes.diamonds}?availableonly=${availableOnly}`;

  const result = await Axios.get(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const getAllDiamondsForAdmin = async (availableOnly: boolean): Promise<any> => {
  const route = `${routes.diamondsAdmin}?availableonly=${availableOnly}`;
  const result = await Axios.get(route, {
    headers: authorizationHeader(),
  });
  return result.data;
};

const getDiamond = async (id: number): Promise<Product<DiamondsType>> => {
  const route = routes.diamond.replace("{id}", id.toString());
  const result = await Axios.get<Product<DiamondsType>>(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const getHighlightedDiamond = async (): Promise<Product<DiamondsType>> => {
  const result = await Axios.get<Product<DiamondsType>>(routes.getHighlighted, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const highlight = async (id: number): Promise<void> => {
  const route = routes.highlight.replace("{id}", id.toString());
  await Axios.patch(route, null, {
    headers: authorizationHeader(),
  });
};

const importCsv = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);

  await Axios.post(routes.import, formData, {
    headers: authorizationHeader(),
  });
};

const importAdditionalCsv = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);

  await Axios.post(routes.importAdditional, formData, {
    headers: authorizationHeader(),
  });
};


const updateDiamond = async (diamondId: number, request: UpsertDiamondModel): Promise<Product<DiamondsType>> => {
  const data = removeEmptyStrings(request);
  const route = routes.diamond.replace("{id}", diamondId.toString());
  const result = await Axios.put<Product<DiamondsType>>(route, data, {
    headers: authorizationHeader(),
  });

  return result.data;
};

export {
  addDiamond,
  changeActiveStatus,
  changeStatus,
  getAllDiamonds,
  getDiamond,
  highlight,
  getHighlightedDiamond,
  importCsv,
  updateDiamond,
  importAdditionalCsv ,
  getAllDiamondsForAdmin
};
