import { AnyAction } from "deox";
import { Dispatch } from "redux";
import { actions as messageActions } from "./messages";

const withErrorDispatch = <T>(
  actionPromise: Promise<T>,
  successCallback: ((result: T) => AnyAction) | null,
  error: string,
  successMessage?: string
) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const result = await actionPromise;
    successCallback && dispatch(successCallback(result));
  } catch (err) {
    messageActions.commit(error, "error")(dispatch);
  }

  successMessage && messageActions.commit(successMessage, "info")(dispatch);
};

export { withErrorDispatch };
