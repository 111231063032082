import {createActionCreator, createReducer} from "deox";
import {produce} from "immer";
import {Dispatch} from "redux";
import {AboutUsConfiguration, Color, Country, IdNameModel, Shape, Support} from "../models/misc";
import * as supportServices from "../services/contact";
import * as dataService from "../services/data";
import * as emailsService from "../services/emails";
import * as siteSettingsService from "../services/siteSettings";
import {ApplicationState} from "./store";

type Maintenance = {
  isEnabled: boolean;
  message: string | null;
};

export type State = {
  [index: string]: any;
  aboutUs: AboutUsConfiguration | null;
  businessTypes: IdNameModel[];
  clarities: IdNameModel[];
  colors: Color[];
  countries: Country[];
  cuts: IdNameModel[];
  emailChannels: IdNameModel[];
  fluors: IdNameModel[];
  labs: IdNameModel[];
  maintenance: Maintenance;
  polishes: IdNameModel[];
  shapes: Shape[];
  symmetries: IdNameModel[];
  support: Support | null;
  fancyIntensities:IdNameModel[];
};

const fetchAboutUs = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await siteSettingsService.getAboutUsConfiguration();
      dispatch(fetchAboutUs.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DATA/ABOUT_US/FETCH", resolve => (aboutUs: AboutUsConfiguration) =>
      resolve(aboutUs)
    ),
  }
);

const fetchBusinessTypes = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await dataService.getBusinessTypes();
      dispatch(fetchBusinessTypes.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DATA/BUSINESS_TYPES/SUCCESS", resolve => (items: IdNameModel[]) => resolve(items)),
  }
);

const fetchCountries = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await dataService.getCountries();
      dispatch(fetchCountries.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DATA/COUNTRIES/SUCCESS", resolve => (items: Country[]) => resolve(items)),
  }
);

const fetchDiamondsRelatedEntities = Object.assign(
  () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    const { data } = getState();
    const { clarities, colors, cuts, fluors, labs, polishes, shapes, symmetries, fancyIntensities, vendor } = data;

    if (
      clarities.length &&
      colors.length &&
      cuts.length &&
      fluors.length &&
      labs.length &&
      polishes.length &&
      shapes.length &&
      symmetries.length &&
      fancyIntensities.length
    ) {
      return;
    }

    try {
      const [clarities, colors, cuts, fluors, labs, polishes, shapes, symmetries, fancyIntensities] = await Promise.all<IdNameModel[]>([
        dataService.diamonds.getClarities(),
        dataService.diamonds.getColors(),
        dataService.diamonds.getCuts(),
        dataService.diamonds.getFluors(),
        dataService.diamonds.getLabs(),
        dataService.diamonds.getPolishes(),
        dataService.diamonds.getShapes(),
        dataService.diamonds.getSymmetries(),
        dataService.diamonds.getFancyIntensities(),
      ]);

      dispatch(fetchDiamondsRelatedEntities.success({ itemType: "clarities", values: clarities }));
      dispatch(fetchDiamondsRelatedEntities.success({ itemType: "colors", values: colors }));
      dispatch(fetchDiamondsRelatedEntities.success({ itemType: "cuts", values: cuts }));
      dispatch(fetchDiamondsRelatedEntities.success({ itemType: "fluors", values: fluors }));
      dispatch(fetchDiamondsRelatedEntities.success({ itemType: "labs", values: labs }));
      dispatch(fetchDiamondsRelatedEntities.success({ itemType: "polishes", values: polishes }));
      dispatch(fetchDiamondsRelatedEntities.success({ itemType: "shapes", values: shapes }));
      dispatch(fetchDiamondsRelatedEntities.success({ itemType: "symmetries", values: symmetries }));
      dispatch(fetchDiamondsRelatedEntities.success({ itemType: "fancyIntensities", values: fancyIntensities }));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator(
      "@@DATA/DIAMONDS_ENTITIES/SUCCESS",
      resolve => (item: { itemType: string; values: IdNameModel[] }) => resolve(item)
    ),
  }
);

const fetchEmailChannels = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await emailsService.getChannels();
      dispatch(fetchEmailChannels.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DATE/EMAILS/CHANNELS", resolve => (channels: IdNameModel[]) => resolve(channels)),
  }
);

const fetchMaintenanceMessage = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await siteSettingsService.getMaintenanceMessage();
      dispatch(fetchMaintenanceMessage.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DATA/MAINTENANCE_MESSAGE/FETCH", resolve => (message: string) => resolve(message)),
  }
);

const fetchMaintenanceState = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await siteSettingsService.getMaintenance();
      dispatch(fetchMaintenanceState.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DATA/MAINTENANCE/FETCH", resolve => (state: boolean) => resolve(state)),
  }
);

const fetchSupportInfo = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await supportServices.getSupportInfo();
      dispatch(fetchSupportInfo.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DATA/SUPPORT/FETCH", resolve => (support: Support) => resolve(support)),
  }
);

const defaultState: State = {
  aboutUs: null,
  businessTypes: [],
  clarities: [],
  colors: [],
  countries: [],
  cuts: [],
  emailChannels: [],
  fancyIntensities:[],
  fluors: [],
  maintenance: {
    isEnabled: false,
    message: null,
  },
  labs: [],
  polishes: [],
  shapes: [],
  symmetries: [],
  support: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchAboutUs.success, (state, action) => ({ ...state, aboutUs: action.payload })),
  handleAction(fetchBusinessTypes.success, (state, action) => ({ ...state, businessTypes: action.payload })),
  handleAction(fetchCountries.success, (state, action) => ({ ...state, countries: action.payload })),
  handleAction(fetchDiamondsRelatedEntities.success, (state, action) =>
    produce(state, draft => {
      draft[action.payload.itemType] = action.payload.values;
    })
  ),
  handleAction(fetchEmailChannels.success, (state, action) => ({ ...state, emailChannels: action.payload })),
  handleAction(fetchMaintenanceMessage.success, (state, action) => ({
    ...state,
    maintenance: { ...state.maintenance, message: action.payload },
  })),
  handleAction(fetchMaintenanceState.success, (state, action) => ({
    ...state,
    maintenance: { ...state.maintenance, isEnabled: action.payload },
  })),
  handleAction(fetchSupportInfo.success, (state, action) => ({ ...state, support: action.payload })),
]);

const actions = {
  fetchAboutUs,
  fetchBusinessTypes,
  fetchCountries,
  fetchDiamondsRelatedEntities,
  fetchEmailChannels,
  fetchMaintenanceMessage,
  fetchMaintenanceState,
  fetchSupportInfo,
};

export {actions, reducer};
