import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import history from "../history";
import { Order } from "../models/orders";
import * as orderServices from "../services/orders";
import { ApplicationState } from "./store";
import { actions as authActions } from "./auth"

export type State = {
  data: Order[] | null;
};

const _fetchAllOrders = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { orders } = getState();

  if (orders.data) {
    return;
  }

  try {
    const data = await orderServices.getAllUserOrders();
    dispatch(fetchAllOrders.success(data));
  } catch {
    return;
  }
};

const _createOrder = (redirectUrl?: string) => async (dispatch: Dispatch) => {
  try {
    const data = await orderServices.createOrder();
    dispatch(createOrder.success(data));
    history.push(redirectUrl ? `${redirectUrl}/${data.id}` : "/");
  } catch {
    return;
  }
};

const fetchAllOrders = Object.assign(_fetchAllOrders, {
  success: createActionCreator("@@ORDER/SUCCESS", resolve => (orders: Order[]) => resolve(orders)),
});

const createOrder = Object.assign(_createOrder, {
  success: createActionCreator("@@ORDER/ADD/SUCCESS", resolve => (order: Order) => resolve(order)),
});

const defaultState: State = { data: null };

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchAllOrders.success, (state, action) =>
    produce(state, draft => {
      draft.data = action.payload;
    })
  ),
  handleAction(createOrder.success, (state, action) =>
    produce(state, draft => {
      draft.data = (state.data || []).concat(action.payload);
    })
  ),
  handleAction(authActions.logout.success, (state, action) =>
    produce(state, draft => {
      draft.data = [];
  }))
]);

const actions = {
  createOrder,
  fetchAllOrders,
};

export { actions, reducer };
