import Axios, { AxiosResponse } from 'axios';
import { authorizationHeader } from './apiUtils';
import { removeEmptyStrings } from '../utils';
import { Partner } from '../models/misc';

const routes = {
  partners: '/api/product-partners',
  partner: '/api/product-partners/{id}',
};

const getAllPartners = async (): Promise<Partner[]> => {
  const result = await Axios.get<Partner[]>(routes.partners, {
    headers: authorizationHeader(),
  });
  return result.data;
};

const addPartner = async (partner: Omit<Partner, 'id'>): Promise<Partner> => {
  const data = removeEmptyStrings(partner);

  const result = await Axios.post<Partner>(routes.partners, data, {
    headers: authorizationHeader(),
  });
  return result.data;
};

const editPartner = async (partner: Partner): Promise<Partner> => {
  const route = `/api/product-partners/${encodeURIComponent(partner.partnerName)}`;
  const result = await Axios.put<Partner>(route, partner, {
    headers: authorizationHeader(),
  });
  return result.data;
};

const deletePartner = async (id: number): Promise<void> => {
  const route = routes.partner.replace('{id}', id.toString());
  await Axios.delete(route, {
    headers: authorizationHeader(),
  });
};

const exportPartnerTemplate = async (partnerName: string): Promise<Blob> => {
  const result = await Axios.get(`/api/product-partners/${encodeURIComponent(partnerName)}/export`, {
    headers: authorizationHeader(),
    responseType: 'blob',
  });
  return result.data;
};

export { getAllPartners, addPartner, deletePartner, editPartner, exportPartnerTemplate};
