import { createActionCreator, createReducer } from "deox";
import { Dispatch } from "redux";
import { REFETCH_DATA_INTERVAL } from "../constants";
import { MeleeStone, Product } from "../models/products";
import * as meleeService from "../services/melee";
import { store } from "./store";

export type State = Product<MeleeStone>[];

const fetchMelees = Object.assign(
  () => async (dispatch: Dispatch) => {
    const { melee } = store.getState()
    try {
      if(melee.length === 0){
        const data = await meleeService.getAll(true);
        dispatch(fetchMelees.success(data));

      }

      setInterval(async () => {
        const data = await meleeService.getAll(true);
        dispatch(fetchMelees.success(data));
      }, REFETCH_DATA_INTERVAL);
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@MELEES/FETCH", resolve => (melees: Product<MeleeStone>[]) => resolve(melees)),
  }
);

const defaultState: State = [];

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchMelees.success, (state, action) => action.payload),
]);

const actions = {
  fetchMelees,
};

export { actions, reducer };
