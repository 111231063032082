import { createActionCreator, createReducer } from "deox";
import { Show } from "../models/misc";
import * as showsService from "../services/shows";
import { withErrorDispatch } from "./admin/withErrorDispatch";

export type State = Show[];

const fetchAllShows = Object.assign(
  () =>
    withErrorDispatch(showsService.getAll(true), result => fetchAllShows.success(result), "Error fetching all shows"),
  {
    success: createActionCreator("@@ADMIN/SHOWS/GET_ALL", resolve => (shows: Show[]) => resolve(shows)),
  }
);

const defaultState: State = [];
const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchAllShows.success, (state, action) => action.payload),
]);

const actions = {
  fetchAllShows,
};

export { actions, reducer };
