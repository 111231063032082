export enum ErrorCode {
  ACCOUNT_NOT_APPROVED = 10,
  ADDRESS_NOT_FOUND = 20,
  DIAMOND_NOT_FOUND = 30,
  EMAIL_ALREADY_EXISTS = 40,
  EMAIL_NOT_VERIFIED = 50,
  EMAIL_TEMPLATE_NOT_FOUND = 60,
  FORBIDDEN = 70,
  INTERNAL_ERROR = 80,
  INVALID_RECAPTCHA = 85,
  INVALID_REQUEST = 90,
  ITEM_NOT_FOUND = 95,
  WRONG_PASSWORD = 100,
  ORDER_NOT_FOUND = 110,
  SHOW_NOT_FOUND = 120,
  USER_BLOCKED = 130,
  USER_NOT_FOUND = 140,
  VERIFICATION_FAILED = 150,
}

export interface ErrorResponse {
  errorCode: ErrorCode;
}

const phrases: { [index in ErrorCode]: string } = {
  [ErrorCode.ACCOUNT_NOT_APPROVED]: "Your account is not approved by an Administrator",
  [ErrorCode.ADDRESS_NOT_FOUND]: "",
  [ErrorCode.DIAMOND_NOT_FOUND]: "",
  [ErrorCode.EMAIL_ALREADY_EXISTS]: "This email is already registered in the system",
  [ErrorCode.EMAIL_NOT_VERIFIED]: "Your email is not verified",
  [ErrorCode.EMAIL_TEMPLATE_NOT_FOUND]: "",
  [ErrorCode.FORBIDDEN]: "",
  [ErrorCode.INTERNAL_ERROR]: "",
  [ErrorCode.INVALID_RECAPTCHA]: "Invalid ReCAPTCHA",
  [ErrorCode.INVALID_REQUEST]: "",
  [ErrorCode.ITEM_NOT_FOUND]: "The selectred item was not found",
  [ErrorCode.WRONG_PASSWORD]: "The provided password does not match this email",
  [ErrorCode.ORDER_NOT_FOUND]: "",
  [ErrorCode.SHOW_NOT_FOUND]: "",
  [ErrorCode.USER_BLOCKED]: "Your account has been blocked",
  [ErrorCode.USER_NOT_FOUND]: "Your email does not exist in our system",
  [ErrorCode.VERIFICATION_FAILED]: "",
};

const getErrorPhrase = (error: ErrorCode): string => phrases[error] || "General Error";

export { getErrorPhrase };
