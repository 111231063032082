import Axios from "axios";
import { UploadFile } from "../models/misc";
import ReactGa from "react-ga";
import {
  IAccessUser,
  ILoginAccessResponse,
  ILoginResponse,
  IMarkupUser,
  IRegisterRequest,
  IRegisterRequestFromEmail,
  IUpsertAddressRequest,
  IUserAccess,
  IUserAddress,
  IUserAddressWithEmail,
  IUserMarkup,
  IUserProfile,
  IUserVendor,
  User,
} from "../models/users";
import { authorizationHeader } from "./apiUtils";

const routes = {
  addAddress: "/api/users/current/addresses",
  changePassword: "/api/users/change-password",
  changePasswordByToken: "/api/auth/change-password",
  changeUserBlockStatus: "/api/users/{id}/blocked",
  changeUserRole: "/api/users/{id}/role",
  changeUserStatus: "/api/users/{id}/status",
  confirmEmail: "/api/auth/confirm-email",
  currentUser: "/api/users/current",
  editAccount: "/api/users",
  getAllUsers: "/api/users",
  login: "/api/auth/login",
  logo: "/api/users/{id}/logo",
  background: "/api/users/{id}/background",
  refreshToken: "/api/users/current/refresh-token",
  register: "/api/auth/register",
  resentConfirmationEmail: "/api/auth/resend-confirm-email",
  resetPassword: "/api/auth/reset-password",
  updateAddress: "/api/users/{userId}/addresses/{id}",
  updateSubscriptions: "/api/users/current/newsletter/subscriptions",
  updatePrimaryColor: "/api/users/{userId}/primaryColor",
  updateDomain: "/api/users/{userId}/domain",
  addUserMarkup: "/api/users/{userId}/markup",
  addUserVendor: "/api/users/{userId}/add-vendor",
  suspendUserCell: "/api/users/{userId}/suspend-cell",
  resumeUserCell: "/api/users/{userId}/resume-cell",
  deleteUserVendor: "/api/users/{userId}/delete-vendor",
  addUserAccess: "/api/users/{userId}/access",
  deleteUserMarkup: "/api/users/markup/{markupId}",
  deleteUserAccess: "/api/users/access/{accessId}",
  deleteUser: "/api/users/{userId}",
  suspendUser: "/api/users/{userId}/suspendUser",
  resumeVendorUser: "/api/users/{userId}/resumeVendorUser"
};

const addAddress = async (address: IUpsertAddressRequest): Promise<IUserAddress> => {
  const result = await Axios.post(routes.addAddress, address, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const changePassword = async (currentPassword: string, newPassword: string): Promise<void> =>
  await Axios.post(
    routes.changePassword,
    { currentPassword, newPassword },
    {
      headers: authorizationHeader(),
    }
  );

const changePasswordByToken = async (email: string, token: string, newPassword: string): Promise<void> =>
  await Axios.post(routes.changePasswordByToken, {
    email,
    token,
    password: newPassword,
  });

const changeUserBlockedStatus = async (userId: string, blocked: boolean, blockedUntil?: Date): Promise<void> => {
  const route = routes.changeUserBlockStatus.replace("{id}", userId);
  await Axios.patch(
    route,
    { isBlocked: blocked, blockedUntil },
    {
      headers: authorizationHeader(),
    }
  );
};

const changeUserRole = async (userId: string, newRole: string | null): Promise<void> => {
  const route = routes.changeUserRole.replace("{id}", userId);
  await Axios.patch(
    route,
    { newRole },
    {
      headers: authorizationHeader(),
    }
  );
};

const changeUserStatus = async (userId: string, approved: boolean): Promise<void> => {
  const route = routes.changeUserStatus.replace("{id}", userId);
  await Axios.patch(
    route,
    { isApproved: approved },
    {
      headers: authorizationHeader(),
    }
  );
};

const confirmEmail = async (email: string, token: string): Promise<void> =>
  await Axios.post(routes.confirmEmail, { email, token });

const editAccount = async (
  firstName: string,
  lastName: string,
  businessTypeId: number | null,
  userId?: string
): Promise<void> => {
  const route = userId ? `${routes.editAccount}/${userId}` : routes.editAccount;

  return await Axios.patch(
    route,
    { firstName, lastName, businessTypeId },
    {
      headers: authorizationHeader(),
    }
  );
};

const getAllUsers = async (): Promise<IUserProfile[]> => {
  const result = await Axios.get<IUserProfile[]>(routes.getAllUsers, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const getUser = async (token: string): Promise<ILoginResponse> => {
  const result = await Axios.get<IUserProfile>(routes.currentUser, {
    headers: authorizationHeader(token),
  });

  return {
    user: result.data,
    accessToken: token,
  };
};

const login = async (email: string, password: string): Promise<ILoginResponse> => {
  const result = await Axios.post<IUserProfile>(routes.login, {
    email,
    password,
  });

  const userPrimaryColor = result.data.primaryColor;
  if (userPrimaryColor !== null) {
    document.documentElement.style.cssText = `--bright-color: ${userPrimaryColor}`;
    localStorage.setItem("primaryColor", userPrimaryColor);
  }
  ReactGa.set({ userId: result.data.id });
  return {
    user: result.data,
    accessToken: result.headers["x-access-token"],
  };
};

const refreshToken = async (token: string): Promise<string> => {
  const result = await Axios.get<string>(routes.refreshToken, {
    headers: authorizationHeader(token),
  });

  return result.data;
};

const register = async <P extends IRegisterRequestFromEmail | IRegisterRequest>(
  registerRequest: P
): Promise<IUserProfile> => {
  const result = await Axios.post<IUserProfile>(routes.register, registerRequest, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const resendConfirmationEmail = async (email: string): Promise<void> =>
  await Axios.post(routes.resentConfirmationEmail, { email });

const resetPassword = async (email: string): Promise<void> => await Axios.post(routes.resetPassword, { email });

const updateAddress = async (
  id: number,
  address: IUpsertAddressRequest,
  userId = "current"
): Promise<IUserAddressWithEmail> => {
  const route = routes.updateAddress.replace("{id}", id.toString()).replace("{userId}", userId);
  const result = await Axios.put(route, address, {
    headers: authorizationHeader(),
  });
  return result.data;
};

const suspendUser = async (id: string, vendors: string[]): Promise<{ vendor: string }> => {
  const route = routes.suspendUser.replace("{id}", id.toString()).replace("{userId}", id);
  const res = await Axios.put(route, { vendors }, {
    headers: authorizationHeader(),
  });
  return res.data;
};

const resumeVendorUser = async (id: string, vendors: string[]): Promise<{ vendor: string }> => {
  const route = routes.resumeVendorUser.replace("{id}", id.toString()).replace("{userId}", id);
  const res = await Axios.delete(route, { headers: authorizationHeader(), data: { vendors } });

  return res.data;
};

const updateSubscriptions = async (ids: number[]): Promise<void> => {
  await Axios.patch(
    routes.updateSubscriptions,
    { channelIds: ids },
    {
      headers: authorizationHeader(),
    }
  );
};

const upsertAgentLogo = async (userId: string, image: UploadFile): Promise<string> => {
  const route = routes.logo.replace("{id}", userId);
  const result = await Axios.put(
    route,
    { image },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const upsertAgentBackground = async (userId: string, image: UploadFile): Promise<string> => {
  const route = routes.background.replace("{id}", userId);
  const result = await Axios.put(
    route,
    { image },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const updatePrimaryColor = async (userId: string, color: string): Promise<string> => {
  const route = routes.updatePrimaryColor.replace("{userId}", userId);
  const result = await Axios.patch(route, { color }, { headers: authorizationHeader() });

  return result.data;
};

const updateUserDomain = async (userId: string, domain: string): Promise<string> => {
  const route = routes.updateDomain.replace("{userId}", userId);
  const result = await Axios.patch(route, { domain }, { headers: authorizationHeader() });

  return result.data;
};

const addUserMarkup = async (
  userId: string,
  caratMin: number | null | undefined,
  caratMax: number | null | undefined,
  priceMin: number | null | undefined,
  priceMax: number | null | undefined,
  percent: number | null | undefined
): Promise<IUserMarkup> => {
  const route = routes.addUserMarkup.replace("{userId}", userId);
  const result = await Axios.post(
    route,
    { caratMin: caratMin, caratMax: caratMax, priceMin: priceMin, priceMax: priceMax, percent: percent },
    { headers: authorizationHeader() }
  );

  console.log(result);

  return result.data;
};

const addUserVendor = async (userId: string, vendor: string): Promise<{ vendor: string }> => {
  const route = routes.addUserVendor.replace("{userId}", userId);
  const response = await Axios.post<{ vendor: string }>(
    route,
    { vendor },
    {
      headers: authorizationHeader(),
    }
  );

  return response.data;
};


const deleteUserVendor = async (userId: string, vendor: string): Promise<{ userId: string, vendor: string }> => {
  const route = routes.deleteUserVendor.replace("{userId}", userId);
  await Axios.delete(route, { headers: authorizationHeader(), data: { vendor } });

  return { userId, vendor };
};

const suspendUserCell = async (userId: string, cell: string): Promise<{ cell: string }> => {
  const route = routes.suspendUserCell.replace("{userId}", userId);
  const response = await Axios.post<{ cell: string }>(
    route,
    { cell },
    {
      headers: authorizationHeader(),
    }
  );

  return response.data;
};


const resumeUserCell = async (userId: string, cell: string): Promise<{ userId: string, cell: string }> => {
  const route = routes.resumeUserCell.replace("{userId}", userId);
  await Axios.delete(route, { headers: authorizationHeader(), data: { cell } });

  return { userId, cell };
};

const addUserAccess = async (userId: string, pageId: string[]): Promise<IUserAccess> => {
  const route = routes.addUserAccess.replace("{userId}", userId);

  const result = await Axios.post(route, { pageId }, { headers: authorizationHeader() });

  return result.data;
};

const deleteUserMarkup = async (markupId: number, userId: string): Promise<IMarkupUser> => {
  const route = routes.deleteUserMarkup.replace("{markupId}", markupId.toString());
  await Axios.delete(route, { headers: authorizationHeader() });

  return {
    markupId,
    userId,
  };
};

const deleteUserAccess = async (accessId: number, userId: string): Promise<IAccessUser> => {
  const route = routes.deleteUserAccess.replace("{accessId}", accessId.toString());
  await Axios.delete(route, { headers: authorizationHeader() });

  return {
    accessId,
    userId,
  };
};

const deleteUser = async (userId: string): Promise<string> => {
  const route = routes.deleteUser.replace("{userId}", userId);
  const response = await Axios.delete(route, { headers: authorizationHeader() });
  return response.data;
};

export {
  addAddress,
  changePassword,
  changePasswordByToken,
  changeUserBlockedStatus,
  changeUserRole,
  changeUserStatus,
  confirmEmail,
  editAccount,
  getAllUsers,
  getUser,
  login,
  refreshToken,
  register,
  resendConfirmationEmail,
  resetPassword,
  updateAddress,
  updateSubscriptions,
  upsertAgentLogo,
  upsertAgentBackground,
  updatePrimaryColor,
  updateUserDomain,
  addUserMarkup,
  deleteUserMarkup,
  deleteUserAccess,
  addUserAccess,
  deleteUser,
  addUserVendor,
  deleteUserVendor,
  suspendUser,
  resumeVendorUser,
  suspendUserCell,
  resumeUserCell
};
