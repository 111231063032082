import { withErrorDispatch } from "./withErrorDispatch";
import { createActionCreator, createReducer } from "deox";
import  * as basketServices from "../../services/basket"
import { Basket } from "../../models/basket";

export type State = Basket[];

const fetchBasketItems = Object.assign(
  () =>
    withErrorDispatch(basketServices.getAll(), result => fetchBasketItems.success(result),  "Error fetching basket items"),
  {
    success: createActionCreator("@@ADMIN/BASKET_ITEMS", resolve => (result: Basket[]) => resolve(result))
  }
);

const defaultState: State = [];

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchBasketItems.success, (state, action) => action.payload)
])

const actions = {
  fetchBasketItems
}

export {actions, reducer}