import Axios from "axios";
import { MeleeColor, NameDiamond, Product } from "../models/products";
import { authorizationHeader } from "./apiUtils";

const colors = Object.keys(MeleeColor).filter(k => typeof MeleeColor[k as any] === "number");

const routes = {
  nameDiamond: "/api/namediamond",
  import: "/api/namediamond/import",
};

const getAll = async (): Promise<Product<NameDiamond>[]> => {
  const route = routes.nameDiamond;

  const result = await Axios.get<Product<NameDiamond>[]>(route, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const importCsv = async (file: File): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);

  await Axios.post(routes.import, formData, {
    headers: authorizationHeader(),
  });
};

export { getAll, importCsv, colors };
