import { createReducer, createActionCreator } from "deox";
import { iteratorSymbol } from "immer/dist/internal";
import { iteratee } from "lodash";
import { Dispatch } from "redux";
import { diamonds } from "../services/data";
import { ApplicationState, store } from "./store";

export type ViewMode = "grid" | "list";

export type State = {
  viewMode: ViewMode;
  showSelected: boolean;
  isHamburgerOpen: boolean;
  wishListForSets: number[] | null;
};

const changeView = Object.assign(
  {},
  {
    success: createActionCreator("@@MISC/CHANGE_VIEW_MODE", resolve => (mode: ViewMode) => resolve(mode)),
  }
);

const setShowSelected = Object.assign(
  {},
  {
    success: createActionCreator("@@MISC/SET_SHOW_SELECTED", resolve => (value: boolean) => resolve(value)),
  }
);

const toggleHamburger = Object.assign(
  {},
  {
    success: createActionCreator("@@MISC/TOGGLE_HAMBURGER", resolve => () => resolve()),
  }
);

const setWishListForSets = Object.assign(
  (itemId: number) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    // const diamondIds = getState().diamonds.all?.filter(d => itemIds.includes(d.id))
    dispatch(setWishListForSets.success(itemId));
  },
  {
    success: createActionCreator("@@MISC/SET_WISHLIST_FOR_SETS", resolve => (data: number) => resolve(data)),
  }
);

const defaultState: State = {
  viewMode: "grid",
  showSelected: false,
  isHamburgerOpen: false,
  wishListForSets: [],
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeView.success, (state, action) => ({
    ...state,
    viewMode: action.payload,
  })),
  handleAction(setShowSelected.success, (state, action) => ({
    ...state,
    showSelected: action.payload,
  })),
  handleAction(toggleHamburger.success, (state, action) => ({
    ...state,
    isHamburgerOpen: !state.isHamburgerOpen,
  })),
  handleAction(setWishListForSets.success, (state, action) => {
    return {
      ...state,
      wishListForSets:
        !!state.wishListForSets && !state.wishListForSets.includes(action.payload)
          ? [...(state.wishListForSets || []), action.payload]
          : state.wishListForSets?.length === 0
          ? [action.payload]
          : (state.wishListForSets || []).filter(id => id !== action.payload),
    };
  }),
]);

const actions = {
  changeView,
  setShowSelected,
  toggleHamburger,
  setWishListForSets,
};

export { actions, reducer };
