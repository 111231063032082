import { createActionCreator, createReducer } from "deox";
import { Dispatch } from "redux";
import { ContactInformation, ContactUsRequest } from "../models/misc";
import * as contactService from "../services/contact";

export type State = {
  information: ContactInformation | null;
};

const fetchContactInformation = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await contactService.getContactInformation();
      dispatch(fetchContactInformation.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@DATA/CONTACT_INFORMATION/FETCH", resolve => (data: ContactInformation) =>
      resolve(data)
    ),
  }
);

const sendMail = Object.assign(
  (request: ContactUsRequest) => async (dispatch: Dispatch) => {
    try {
      const response = await contactService.sendContactUsMail(request);
      return response
    } catch {
      return;
    }
  },
  {}
);

const defaultState: State = {
  information: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchContactInformation.success, (state, action) => ({ ...state, information: action.payload })),
]);

const actions = {
  fetchContactInformation,
  sendMail,
};

export { actions, reducer };
