import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import jwt from "jsonwebtoken";
import { Dispatch } from "redux";
import history from "../history";
import { IdNameModel } from "../models/misc";
import {
  ILoginResponse,
  IRegisterRequestFromEmail,
  IUpsertAddressRequest,
  IUserAddress,
  IUserProfile,
} from "../models/users";
import * as authService from "../services/auth";
import { ErrorResponse, getErrorPhrase } from "../services/errorResponse";
import { ApplicationState } from "./store";
import ReactGa from "react-ga";
import { withErrorDispatch } from "./admin/withErrorDispatch";
export type State = {
  email: string | null;
  retrieved: boolean;
  token: string | null;
  user: IUserProfile | null;
};

let _timeout: NodeJS.Timeout | null = null;

const _changePassword = (currentPassword: string, newPassword: string) => async () => {
  try {
    await authService.changePassword(currentPassword, newPassword);
  } catch (err) {
    return;
  }

  history.push("/account");
};

const _changePasswordByToken = (password: string) => async () => {
  const query = history.location.search;
  const search = new URLSearchParams(query);

  const email = search.get("email");
  const token = search.get("token");

  if (!email || !token || !password) {
    return;
  }

  try {
    await authService.changePasswordByToken(email, token, password);
  } catch {
    return;
  }

  history.push("/login");
};

const _confirmEmail = () => async () => {
  const query = history.location.search;
  const search = new URLSearchParams(query);

  const email = search.get("email");
  const token = search.get("token");

  if (!email || !token) {
    return;
  }

  try {
    await authService.confirmEmail(email, decodeURIComponent(token));
  } catch {
    return;
  }

  history.push("/login");
};

const _editAccount = (
  firstName: string,
  lastName: string,
  setPassword: boolean,
  currentPassword?: string,
  newPassword?: string
) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { auth } = getState();
  const { address, city, country, isBilling, phone, company, federation, ...rest } = auth.user!.addresses[0];
  const email = auth.user!.email;

  let newAddress: IUserAddress | null = null;
  try {
    if (firstName !== rest.firstName || lastName !== rest.lastName) {
      newAddress = await authService.updateAddress(rest.id, {
        address,
        city,
        countryId: country.id,
        isBilling,
        phone,
        company,
        federationId: federation?.id || undefined,
        firstName,
        lastName,
        email,
      });
    }

    if (setPassword && currentPassword && newPassword) {
      await authService.changePassword(currentPassword, newPassword);
    }
  } catch {
    return;
  }

  dispatch(common.updateAddress(newAddress!));

  history.push("/account");
};

const _handleLogin = (email: string, password: string, rememberMe: boolean, returnUrl?: string) => async (
  dispatch: Dispatch
) => {
  try {
    const result = await authService.login(email, password);
    localStorage.removeItem("rememberMe");
    if (rememberMe) {
      localStorage.setItem("rememberMe", email);
    }

    localStorage.setItem("access-token", result.accessToken);
    refreshToken(result.accessToken, dispatch);
    dispatch(login.success(result));

    history.push(returnUrl ? returnUrl : "/");
  } catch (err) {
    const error = err.response?.data as ErrorResponse;
    dispatch(login.error(getErrorPhrase(error.errorCode)));
  }
};

const _initUserState = () => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const token = localStorage.getItem("access-token");
  const { auth } = getState();
  if (auth.user) {
    return;
  }
  if (!token) {
    dispatch(login.noUser());
    return;
  }

  const payload = jwt.decode(token, { json: true });

  if (!payload || payload["exp"] * 1000 < Date.now()) {
    dispatch(login.noUser());
    return;
  }

  let result: ILoginResponse;

  try {
    result = await authService.getUser(token);
    ReactGa.set({ userId: result.user.id });
  } catch {
    dispatch(login.noUser());
    history.push("/login", { from: history.location.pathname });
    return;
  }

  refreshToken(token, dispatch);
  dispatch(login.success(result));
};

const _logout = () => async (dispatch: Dispatch) => {
  dispatch(logout.success());
  localStorage.removeItem("access-token");
  clearTimeout(_timeout!);
  history.push("/");
};

const _register = (request: IRegisterRequestFromEmail) => async (dispatch: Dispatch) => {
  try {
    await authService.register(request);
  } catch (err) {
    const error = err.response?.data as ErrorResponse;
    dispatch(register.error(getErrorPhrase(error.errorCode)));
    return;
  }

  dispatch(register.success(request.email));
  history.push("/welcome", { shouldApprove: request.shouldApprove });
};

const _resendConfirmationEmail = (email: string) => async () => {
  if (!email) {
    return;
  }

  try {
    await authService.resendConfirmationEmail(email);
  } catch {
    return;
  }
};

const _resetPassword = (email: string, noRedirect?: boolean) => async () => {
  try {
    await authService.resetPassword(email);
  } catch (err) {
    return;
  }

  if (!noRedirect) {
    history.push("/");
  }
};

const _updateSubscriptions = (ids: number[]) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
  const { data } = getState();

  try {
    await authService.updateSubscriptions(ids);

    const channels = data.emailChannels.filter(c => ids.includes(c.id));

    dispatch(updateSubscriptions.success(channels));
  } catch {
    return;
  }
};

const _upsertAddress = (id: number | undefined, address: IUpsertAddressRequest, noRedirect?: boolean) => async (
  dispatch: Dispatch
) => {
  try {
    const result = id ? await authService.updateAddress(id, address) : await authService.addAddress(address);

    dispatch(id ? upsertAddress.updateSuccess(result) : upsertAddress.createSuccess(result));

    if (!noRedirect) {
      history.push("/account/addressbook");
    }
  } catch {
    return;
  }
};

const refreshToken = async (token: string, dispatch: Dispatch) => {
  const tokenPayload = jwt.decode(token, { json: true });

  if (!tokenPayload) {
    return;
  }

  const timeLeft = (tokenPayload["exp"] * 1000 - Date.now()) * 0.9;

  _timeout = setTimeout(async () => {
    const newToken = await authService.refreshToken(token);
    localStorage.setItem("access-token", newToken);
    dispatch(login.refreshToken(newToken));
    refreshToken(newToken, dispatch);
  }, timeLeft);
};

const changePassword = Object.assign(_changePassword, {});

const changePasswordByToken = Object.assign(_changePasswordByToken, {});

const common = Object.assign(
  {},
  {
    updateAddress: createActionCreator("@@AUTH/UPDATE_USER", resolve => (address: IUserAddress) => resolve(address)),
  }
);

const confirmEmail = Object.assign(_confirmEmail, {});

const editAccount = Object.assign(_editAccount, {});

const initUserState = Object.assign(_initUserState, {});

const login = Object.assign(_handleLogin, {
  success: createActionCreator("@@AUTH/LOGIN/SUCCESS", resolve => (response: ILoginResponse) => resolve(response)),
  noUser: createActionCreator("@@AUTH/LOGIN/NO_USER"),
  error: createActionCreator("@@AUTH/LOGIN/ERROR", resolve => (error: string) => resolve(error)),
  refreshToken: createActionCreator("@@AUTH/LOGIN/REFRESH_TOKEN", resolve => (token: string) => resolve(token)),
});

const logout = Object.assign(_logout, {
  success: createActionCreator("@@AUTH/LOGOUT"),
});

const register = Object.assign(_register, {
  success: createActionCreator("@@AUTH/REGISTER/SUCCESS", resolve => (email: string) => resolve(email)),
  error: createActionCreator("@@AUTH/REGISTER/ERROR", resolve => (error: string) => resolve(error)),
});

const resendConfirmationEmail = Object.assign(_resendConfirmationEmail, {});

const resetPassword = Object.assign(_resetPassword, {});

const updateSubscriptions = Object.assign(_updateSubscriptions, {
  success: createActionCreator("@AUTH/SUBSCRIPTIONS", resolve => (subscriptions: IdNameModel[]) =>
    resolve(subscriptions)
  ),
});

const upsertAddress = Object.assign(_upsertAddress, {
  createSuccess: createActionCreator("@AUTH/ADDRESS/CREATE", resolve => (address: IUserAddress) => resolve(address)),
  updateSuccess: createActionCreator("@AUTH/ADDRESS/UPDATE", resolve => (address: IUserAddress) => resolve(address)),
});

const defaultState: State = {
  email: null,
  retrieved: false,
  user: null,
  token: null,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(login.success, (state, action) => ({
    ...state,
    user: action.payload.user,
    token: action.payload.accessToken,
    retrieved: true,
  })),
  handleAction(login.noUser, () => ({ ...defaultState, retrieved: true })),
  handleAction(login.refreshToken, (state, action) => ({ ...state, token: action.payload })),
  handleAction(logout.success, state => ({ ...state, user: null, token: null })),
  handleAction(register.success, (state, action) => ({ ...state, email: action.payload })),
  handleAction(common.updateAddress, (state, action) =>
    produce(state, draft => {
      draft.user && (draft.user.addresses[0] = action.payload);
    })
  ),
  handleAction(upsertAddress.createSuccess, (state, action) =>
    produce(state, draft => {
      draft.user?.addresses?.push(action.payload);
    })
  ),
  handleAction(updateSubscriptions.success, (state, action) =>
    produce(state, draft => {
      draft.user && (draft.user.emailChannels = action.payload);
    })
  ),
  handleAction(upsertAddress.updateSuccess, (state, action) =>
    produce(state, draft => {
      const restAddrs: IUserAddress[] = draft.user?.addresses.filter(a => a.id !== action.payload.id) || [];
      draft.user?.addresses && (draft.user.addresses = [...restAddrs, action.payload]);
    })
  ),
]);

const actions = {
  changePassword,
  changePasswordByToken,
  confirmEmail,
  editAccount,
  initUserState,
  login,
  logout,
  register,
  resendConfirmationEmail,
  resetPassword,
  updateSubscriptions,
  upsertAddress,
};

export { actions, reducer };
