import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import moment from "moment";
import { Order, OrderStatus, UpsertOrder } from "../../models/orders";
import * as ordersService from "../../services/orders";
import { withErrorDispatch } from "./withErrorDispatch";

export type State = Order[];

const changeStatus = Object.assign(
  (orderId: number, newStatus: OrderStatus) =>
    withErrorDispatch(
      ordersService.changeStatus(orderId, newStatus),
      () => changeStatus.success({ orderId, newStatus }),
      "Error changing order status"
    ),
  {
    success: createActionCreator(
      "@@ADMIN/ORDERS/CHANGE_STATUS",
      resolve => (result: { orderId: number; newStatus: OrderStatus }) => resolve(result)
    ),
  }
);

const createOrder = Object.assign(
  (order: UpsertOrder) =>
    withErrorDispatch(
      ordersService.createOrderAdmin(order),
      result => createOrder.success(result),
      "Error creating order"
    ),
  {
    success: createActionCreator("@@ADMIN/ORDERS/CREATE", resolve => (result: Order) => resolve(result)),
  }
);

const editOrder = Object.assign(
  (id: number, order: UpsertOrder) =>
    withErrorDispatch(
      ordersService.editOrderAdmin(id, order),
      order => editOrder.success(order),
      "Error updating order"
    ),
  {
    success: createActionCreator("@@ADMIN/ORDERS/EDIT", resolve => (result: Order) => resolve(result)),
  }
);

const deleteOrder = Object.assign(
  (id: number) =>
    withErrorDispatch(ordersService.deleteOrder(id), order => deleteOrder.success(id), "Error deleting order"),
  {
    success: createActionCreator("@@ADMIN/ORDERS/DELETE", resolve => (orderId: number) => resolve(orderId)),
  }
);

const fetchOrders = Object.assign(
  () => withErrorDispatch(ordersService.getAll(), result => fetchOrders.success(result), "Error fetching orders"),
  {
    success: createActionCreator("@@ADMIN/ORDERS", resolve => (result: Order[]) => resolve(result)),
  }
);

const defaultState: State = [];

const reducer = createReducer(defaultState, handleAction => [
  handleAction(changeStatus.success, (state, action) =>
    produce(state, draft => {
      const order = draft.find(o => o.id === action.payload.orderId);
      if (order) {
        order.status = action.payload.newStatus;
        order.completedDate =
          action.payload.newStatus === OrderStatus.Cancelled || action.payload.newStatus === OrderStatus.Completed
            ? moment().utc().toDate()
            : null;
      }
    })
  ),
  handleAction(createOrder.success, (state, action) => [...state, action.payload]),
  handleAction(editOrder.success, (state, action) => {
    return [...state.filter(o => o.id !== action.payload.id), action.payload];
  }),
  handleAction(fetchOrders.success, (state, action) => action.payload),
  handleAction(deleteOrder.success, (state, action) => [...state.filter(o => o.id !== action.payload)]),
]);

const actions = {
  changeStatus,
  createOrder,
  deleteOrder,
  editOrder,
  fetchOrders,
};

export { actions, reducer };
