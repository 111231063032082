import Axios from "axios";
import { BulkMeleeType } from "../components/category-list";
import { CartItem } from "../models/cart";
import { authorizationHeader } from "./apiUtils";

const routes = {
  addDiamond: "/api/cart/diamond",
  addSet: "/api/cart/add-set",
  addMelee: "/api/cart/melee",
  addNameDiamond: "/api/cart/namediamond",
  bulkAddDiamonds: "/api/cart/diamond-bulk",
  bulkRemoveDiamonds: "/api/cart/diamond-bulk-remove",
  bulkAddBasket: "/api/cart/basket-bulk-add",
  cart: "/api/cart",
  deleteSet: "/api/cart/remove-set",
};

const getCartItems = async (): Promise<CartItem[]> => {
  const result = await Axios.get<CartItem[]>(routes.cart, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const addDiamondToCart = async (itemId: number, wishedItemId?: number): Promise<CartItem> => {
  if (wishedItemId) {
    const result = await Axios.post(
      routes.addDiamond,
      { itemId, wishedItemId },
      {
        headers: authorizationHeader(),
      }
    );

    return result.data;
  }
  const result = await Axios.post(
    routes.addDiamond,
    { itemId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};
const addSetToCart = async (itemId: number, wishedItemId?: number): Promise<CartItem[]> => {
  const result = await Axios.post(
    routes.addSet,
    { itemId, wishedItemId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addMeleeToCart = async (
  itemId: number,
  numberOfStones?: number,
  carats?: number,
  wishedItemId?: number
): Promise<CartItem> => {
  const result = await Axios.post(
    routes.addMelee,
    { itemId, numberOfStones, carats, wishedItemId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addNameDiamondToCart = async (itemId: number, wishedItemId?: number): Promise<CartItem> => {
  const result = await Axios.post(
    routes.addNameDiamond,
    { itemId, wishedItemId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const bulkAddDiamondsToCart = async (itemIds: number[]): Promise<CartItem[]> => {
  const result = await Axios.post(
    routes.bulkAddDiamonds,
    itemIds.map(id => ({ itemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};
const bulkRemoveDiamondsFromCart = async (itemIds: number[]): Promise<CartItem[]> => {
  const result = await Axios.post(
    routes.bulkRemoveDiamonds,
    itemIds.map(id => ({ itemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const bulkAddBasketToCart = async (diamonds: BulkMeleeType[]): Promise<CartItem[]> => {
  const result = await Axios.post(routes.bulkAddBasket, diamonds, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const deleteCartItem = async (itemId: number): Promise<void> =>
  await Axios.delete(`${routes.cart}/${itemId}`, {
    headers: authorizationHeader(),
  });
const deleteSet = async (itemId: number): Promise<void> =>
  await Axios.delete(`${routes.deleteSet}/${itemId}`, {
    headers: authorizationHeader(),
  });

export {
  addDiamondToCart,
  addSetToCart,
  addMeleeToCart,
  addNameDiamondToCart,
  bulkAddDiamondsToCart,
  deleteCartItem,
  getCartItems,
  bulkRemoveDiamondsFromCart,
  bulkAddBasketToCart,
  deleteSet,
};
