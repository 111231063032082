import { UploadFile } from "./misc";

export interface Product<TProductType> {
  id: number;
  product: TProductType;
  isActive: boolean;
}

enum DiamondStatusEnum {
  Available = 1,
  Hold = 2,
  Suspended = 3,
  Memo = 4,
}

enum MeleeColor {
  Black = 1,
  TTLB = 2,
  White = 3,
}

enum ProductType {
  Diamond = 1,
  Melee = 2,
  NameDiamond = 3,
}

export interface NameDiamond {
  isActive: boolean;
  id: number;
  size: number;
  color: string;
  letter: string;
  totalPrice: number;
  pic?: string;
}

export interface MeleeStone {
  id: number;
  carats: number;
  color: MeleeColor;
  isActive: boolean;
  pricePerCarat: number;
  sizeFrom: number;
  sizeTo: number;
  quantity?: number;
}

export type UpsertMeleeStone = Omit<MeleeStone, "isActive" | "id">;

export type DiamondForBasket = {
  stockId: string;
  qty: number;
  shape: string;
  carats: number;
  colorName: string;
  clarityName: string;
  cutName: string;
  polName: string;
  symName: string;
  depth: number;
  table: number;
  measurement: string;
  pricePerCarat: number;
  discount: string;
  totalPrice: number;
  lab: string;
  certificate: string;
  image: string;
  image3d: string;
};

export type DiamondsType = {
  stockId: string;
  carats: number;
  certificateNo?: string;
  certificateUrl?: string;
  clarityId?: number;
  colorId: number | MeleeColor;
  colorShade?: string;
  comment?: string;
  crownAngle?: number;
  crownHeight?: number;
  culetSize?: number;
  cutId?: number;
  depthPercent?: number;
  discount?: number;
  eyeClean?: string;
  fancyIntensity?: string;
  fluorId?: number;
  fluorescence?: string;
  fluorescenceColor?: string;
  girdle?: number;
  height: number;
  image3d?: string;
  set3D?: string;
  setImage?: string;
  imageUrl?: string;
  pic2?: string;
  pic3?: string;
  pic4?: string;
  isHighlighted?: boolean;
  labId: number;
  length: number;
  milky?: string;
  overtone?: string;
  parcelStones?: number;
  pavilionAngle?: number;
  pavilionDepth?: number;
  polishId?: number;
  pricePerCarat: number;
  rapPrice?: number;
  shapeId: number;
  status: DiamondStatusEnum;
  symmetryId?: number;
  tablePercent?: number;
  totalPrice: number;
  width: number;
  isMatchingPair: boolean;
  memoAccount?: string;
  memoPricePerCarat?: number;
  memoTotalPrice?: number;
  hold?: string;
  holdUserId?: string;
  memoNo?: number;
  memoDate?: string;
  memoRap?: number;
  isLine?: boolean;
  isLabGrown?: boolean;
  isSnP?: boolean;
  lineSet?: string;
  isPair?: boolean;
  wishListId?: number;
  tradeShow?: string;
  ringSize?: number;
  treatment?: string;
  showToAll?: string;
  lineSetColor?: string;
  lineSetClarity?: string;
  imageArray?: string[];
  vendor?: string;
};

export type Diamond = {
  id: number;
  stockId: string;
  carats: number;
  color: string | MeleeColor;
  colorId: number;
  height: number;
  isActive: boolean;
  lab: string;
  labId: number;
  length: number;
  pricePerCarat: number;
  shape: string;
  shapeId: number;
  status: DiamondStatusEnum;
  totalPrice: number;
  width: number;
  isFancy: boolean;
  isMatchingPair: boolean;
  isMelee?: boolean;
  isNameDiamond?: boolean;
  letter?: string;
  size?: number;
  certificateNumber?: string;
  certificateUrl?: string;
  clarity?: string;
  clarityId?: number;
  colorShade?: string;
  colorVariation?: string;
  comment?: string;
  crownAngle?: number;
  crownHeight?: number;
  culetSize?: number;
  cut?: string;
  cutId?: number;
  depthPercent?: number;
  discount?: number;
  eyeClean?: string;
  fancyIntensity?: string;
  fluor?: string;
  fluorId?: number;
  fluorescence?: string;
  fluorescenceColor?: string;
  girdle?: number;
  image3d?: string;
  set3D?: string;
  setImage?: string;
  imageUrl?: string;
  pic2?: string;
  pic3?: string;
  pic4?: string;
  isHighlighted?: boolean;
  milky?: string;
  overtone?: string;
  parcelStones?: number;
  pavilionAngle?: number;
  pavilionDepth?: number;
  polish?: string;
  polishId?: number;
  rapPrice: number;
  symmetry?: string;
  symmetryId?: number;
  tablePercent?: number;
  memoAccount?: string;
  memoPricePerCarat?: number;
  memoTotalPrice?: number;
  hold?: string;
  holdUserId?: string;
  memoNo?: number;
  memoDate?: string;
  memoRap?: number;
  isLine?: boolean;
  isSnP?: boolean;
  isLabGrown?: boolean;
  lineSet?: string;
  isPair?: boolean;
  wishListId?: number;
  tradeShow?: string;
  ringSize?: number;
  treatment?: string;
  showToAll?: string;
  lineSetColor?: string;
  lineSetClarity?: string;
  imageArray?: string[];
  vendor?: string;
};

export type ExcludeProps =
  | "id"
  | "imageUrl"
  | "clarity"
  | "color"
  | "cut"
  | "fluor"
  | "lab"
  | "polish"
  | "shape"
  | "symmetry"
  | "isActive"
  | "status"
  | "totalPrice";

export type UpsertDiamondModel = Omit<DiamondsType, ExcludeProps> & {
  image?: UploadFile;
};

export type UpsertOrderInvoiceModel = {
  invoice?: UploadFile;
  orderId: number;
  userEmail: string | undefined;
  ccEmail?: string;
  title: string;
  description?: string;
};

export { DiamondStatusEnum, MeleeColor, ProductType };
