import { Dispatch } from "redux";
import { actions as messageActions } from "./messages";
import { createActionCreator, createReducer } from "deox";
import { NameDiamond, Product } from "../../models/products";
import * as nameDiamondService from "../../services/nameDiamond";

export type State = Product<NameDiamond>[];

const defaultState: State = [];

const fetchNameDiamonds = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const result = await nameDiamondService.getAll();
      dispatch(fetchNameDiamonds.success(result));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@NAME_DIAMOND/FETCH", resolve => (nameDiamonds: Product<NameDiamond>[]) =>
      resolve(nameDiamonds)
    ),
  }
);

const importCsv = Object.assign(
  (file: File) => async (dispatch: Dispatch) => {
    try {
      await nameDiamondService.importCsv(file);

      messageActions.commit("Import successul. The page will reload to show the updated data", "info")(dispatch);

      setTimeout(() => window.location.reload(), 2000);
    } catch {
      messageActions.commit("Error importing file", "error")(dispatch);
    }
  },
  {}
);

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchNameDiamonds.success, (state, action) => action.payload),
]);

const actions = {
  fetchNameDiamonds,
  importCsv,
};

export { actions, reducer };
