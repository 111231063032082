import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { EmailChannel, EmailTemplate, SubscriptionEmail, UpsertEmailTemplate } from "../../models/misc";
import * as emailsService from "../../services/emails";
import { withErrorDispatch } from "./withErrorDispatch";

export type State = {
  channels: EmailChannel[];
  templates: EmailTemplate[];
};

const createChannel = Object.assign(
  (channel: string) =>
    withErrorDispatch(
      emailsService.createChannel(channel),
      result => createChannel.success(result),
      "Error creating a channel"
    ),
  {
    success: createActionCreator("@@ADMIN/EMAILS/CHANNELS/CREATE", resolve => (channel: EmailChannel) =>
      resolve(channel)
    ),
  }
);

const createTemplate = Object.assign(
  (template: UpsertEmailTemplate) =>
    withErrorDispatch(
      emailsService.createTemplate(template),
      templateId => createTemplate.success({ ...template, id: templateId }),
      "Error creating template"
    ),
  {
    success: createActionCreator("@@ADMIN/EMAILS/TEMPLATES/CREATE", resolve => (template: EmailTemplate) =>
      resolve(template)
    ),
  }
);

const deleteTemplate = Object.assign(
  (templateId: number) =>
    withErrorDispatch(
      emailsService.deleteTemplate(templateId),
      () => deleteTemplate.success(templateId),
      "Error deleting template"
    ),
  {
    success: createActionCreator("@@ADMIN/EMAILS/TEMPLATES/DELETE", resolve => (templateId: number) =>
      resolve(templateId)
    ),
  }
);

const editTemplate = Object.assign(
  (templateId: number, template: UpsertEmailTemplate) =>
    withErrorDispatch(
      emailsService.editTemplate(templateId, template),
      () => editTemplate.success({ ...template, id: templateId }),
      "Error editing template"
    ),
  {
    success: createActionCreator("@@ADMIN/EMAILS/TEMPLATES/EDIT", resolve => (template: EmailTemplate) =>
      resolve(template)
    ),
  }
);

const fetchChannels = Object.assign(
  () =>
    withErrorDispatch(
      emailsService.getChannels(),
      channels => fetchChannels.success(channels),
      "Error fetching email channels"
    ),
  {
    success: createActionCreator("@@ADMIN/EMAILS/CHANNELS", resolve => (channels: EmailChannel[]) => resolve(channels)),
  }
);

const fetchTemplates = Object.assign(
  () =>
    withErrorDispatch(
      emailsService.getTemplates(),
      templates => fetchTemplates.success(templates),
      "Error fetching email templates"
    ),
  {
    success: createActionCreator("@@ADMIN/EMAILS/TEMPLATES", resolve => (templates: EmailTemplate[]) =>
      resolve(templates)
    ),
  }
);

const sendEmail = Object.assign(
  (email: SubscriptionEmail) =>
    withErrorDispatch(emailsService.sendEmail(email), null, "Error sending email", "Email scheduled for sending"),
  {}
);

const defaultState: State = {
  channels: [],
  templates: [],
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(createChannel.success, (state, action) =>
    produce(state, draft => {
      draft.channels.push(action.payload);
    })
  ),
  handleAction(createTemplate.success, (state, action) =>
    produce(state, draft => {
      draft.templates.push(action.payload);
    })
  ),
  handleAction(deleteTemplate.success, (state, action) =>
    produce(state, draft => {
      draft.templates = draft.templates.filter(t => t.id !== action.payload);
    })
  ),
  handleAction(editTemplate.success, (state, action) =>
    produce(state, draft => {
      draft.templates = [...draft.templates.filter(t => t.id !== action.payload.id), action.payload];
    })
  ),
  handleAction(fetchChannels.success, (state, action) => ({ ...state, channels: action.payload })),
  handleAction(fetchTemplates.success, (state, action) => ({ ...state, templates: action.payload })),
]);

const actions = {
  createChannel,
  createTemplate,
  deleteTemplate,
  editTemplate,
  fetchChannels,
  fetchTemplates,
  sendEmail,
};

export { actions, reducer };
