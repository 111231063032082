import _ from "lodash";
import React from "react";
import Black from "./images/diamond-colors/black-gem_25x.png";
import Blue from "./images/diamond-colors/blue-gem_25x.png";
import Brown from "./images/diamond-colors/brown-gem_25x.png";
import Chameleon from "./images/diamond-colors/chameleon-gem_25x.png";
import Gray from "./images/diamond-colors/gray-gem_25x.png";
import Green from "./images/diamond-colors/green-gem_25x.png";
import Orange from "./images/diamond-colors/orange-gem_25x.png";
import Pink from "./images/diamond-colors/pink-gem_25x.png";
import Purple from "./images/diamond-colors/purple-gem_25x.png";
import Red from "./images/diamond-colors/red-gem_25x.png";
import Violet from "./images/diamond-colors/violet-gem_25x.png";
import FancyWhite from "./images/diamond-colors/white-gem_25x.png";
import Yellow from "./images/diamond-colors/yellow-gem_25x.png";
import Champagne from "./images/diamond-colors/champagne-gem-25x.png";
import { ReactComponent as Asscher } from "./images/diamonds-icons/ascher.svg";
import { ReactComponent as AsscherPair } from "./images/diamonds-icons/ascher-pair.svg";
import { ReactComponent as Brilliant } from "./images/diamonds-icons/brilliant.svg";
import { ReactComponent as BrilliantPair } from "./images/diamonds-icons/brilliant-pair.svg";
import { ReactComponent as Cushion } from "./images/diamonds-icons/cushion.svg";
import { ReactComponent as CushionPair } from "./images/diamonds-icons/cushion-pair.svg";
import { ReactComponent as Emerald } from "./images/diamonds-icons/emerald.svg";
import { ReactComponent as EmeraldPair } from "./images/diamonds-icons/emerald-pair.svg";
import { ReactComponent as Heart } from "./images/diamonds-icons/heart.svg";
import { ReactComponent as HeartPair } from "./images/diamonds-icons/heart-pair.svg";
import { ReactComponent as Marquise } from "./images/diamonds-icons/marquise.svg";
import { ReactComponent as MarquisePair } from "./images/diamonds-icons/marquise-pair.svg";
import { ReactComponent as Oval } from "./images/diamonds-icons/oval.svg";
import { ReactComponent as OvalPair } from "./images/diamonds-icons/oval-pair.svg";
import { ReactComponent as Pear } from "./images/diamonds-icons/pear.svg";
import { ReactComponent as PearPair } from "./images/diamonds-icons/pear-pair.svg";
import { ReactComponent as Princess } from "./images/diamonds-icons/princess.svg";
import { ReactComponent as PrincessPair } from "./images/diamonds-icons/princess-pair.svg";
import { ReactComponent as Radiant } from "./images/diamonds-icons/radiant.svg";
import { ReactComponent as RadiantPair } from "./images/diamonds-icons/radiant-pair.svg";
import { ReactComponent as Round } from "./images/diamonds-icons/round.svg";
import { ReactComponent as RoundPair } from "./images/diamonds-icons/round-pair.svg";
import { ReactComponent as Special } from "./images/diamonds-icons/special.svg";
import { ReactComponent as Baguette } from "./images/diamonds-icons/baguette.svg";
import { ReactComponent as Triangle } from "./images/diamonds-icons/triangle.svg";
import { IdNameModel } from "./models/misc";
import { Order, OrderViewModel } from "./models/orders";
import { Diamond, DiamondStatusEnum, MeleeStone, NameDiamond, ProductType } from "./models/products";
import { toDiamond, toMelee, toMeleeForBasket, toNameDiamond } from "./models/utils";
import { ListFilterItem } from "./pages/category/filters/list-filter";
import { ApplicationState } from "./store/store";
import { Basket, BasketViewModel } from "./models/basket";
import { PageId } from "./store/UIState";

const shapesIconMap: { [index: string]: JSX.Element } = {
  Asscher: <Asscher className="special-shape-icon" />,
  Cushion: <Cushion className="special-shape-icon" />,
  "Cushion Brilliant": <Brilliant className="special-shape-icon" />,
  Special: <Special className="special-shape-icon" />,
  Emerald: <Emerald className="special-shape-icon" />,
  Heart: <Heart className="special-shape-icon" />,
  Pear: <Pear className="special-shape-icon" />,
  Marquise: <Marquise className="special-shape-icon" />,
  Oval: <Oval className="special-shape-icon" />,
  Princess: <Princess className="special-shape-icon" />,
  Radiant: <Radiant className="special-shape-icon" />,
  Round: <Round className="special-shape-icon" />,
  Triangle: <Triangle className="special-shape-icon" />,
  "Tapered Baguette": <Baguette className="special-shape-icon" />,
};
const pairShapesIconMap: { [index: string]: JSX.Element } = {
  Asscher: <AsscherPair className="special-shape-icon-pair" />,
  Cushion: <CushionPair className="special-shape-icon-pair" />,
  "Cushion Brilliant": <BrilliantPair className="special-shape-icon-pair" />,
  Special: <Special className="special-shape-icon" />,
  Emerald: <EmeraldPair className="special-shape-icon-pair" />,
  Heart: <HeartPair className="special-shape-icon-pair" />,
  Pear: <PearPair className="special-shape-icon-pair" />,
  Marquise: <MarquisePair className="special-shape-icon-pair" />,
  Oval: <OvalPair className="special-shape-icon-pair" />,
  Princess: <PrincessPair className="special-shape-icon-pair" />,
  Radiant: <RadiantPair className="special-shape-icon-pair" />,
  Round: <RoundPair className="special-shape-icon-pair" />,
  Triangle: <Triangle className="special-shape-icon" />,
  "Tapered Baguette": <Baguette className="special-shape-icon" />,
};

const colorMaps: { [index: string]: string } = {
  Black,
  Blue,
  Brown,
  Chameleon,
  Gray,
  Green,
  Orange,
  Pink,
  Purple,
  Red,
  Violet,
  Yellow,
  FancyWhite,
  Champagne,
};

const keyValueMap = (i: IdNameModel) => ({
  id: i.id,
  display: i.name,
});
const keyValueMapWithTooltip = (i: IdNameModel) => {
  const tooltipValues: { [key: string]: string } = {
    EX: "Excellent",
    VG: "Very Good",
    G: "Good",
    F: "Fair",
    P: "Poor",
    Brilliant: "Brilliant",
    Rose: "Rose",
    Double: "Double",
    Step: "Step",
  };

  return {
    id: i.id,
    display: i.name,
    tooltip: tooltipValues[i.name],
  };
};

const getMainShapes = (state: ApplicationState, pageId?: string): ListFilterItem[] => {
  if (pageId === "/salt&pepper") {
    return state.data.shapes
      .filter(s => !s.parentShape && s.name !== "Special" && s.name.toLowerCase() !== "heart")
      .map(s => ({
        id: s.id,
        display: shapesIconMap[s.name],
        tooltip: s.name,
      }));
  }
  return state.data.shapes
    .filter(s => !s.parentShape && s.name !== "Special")
    .map(s => ({
      id: s.id,
      display: pageId !== "/matching-pairs" ? shapesIconMap[s.name] : pairShapesIconMap[s.name],
      tooltip: s.name,
    }));
};
const getSelectedShapes = (...shapesToAdd: string[]) => (state: ApplicationState): ListFilterItem[] => {
  const shapes = shapesToAdd.filter((s, i) => state.data.shapes.some(d => d.name === s));

  const newShapes = shapes.map(s => {
    const findShape = state.data.shapes.find(f => f.name === s);
    return {
      id: findShape!.id,
      display:
        !findShape!.parentShape || !!shapesIconMap[findShape!.name] ? (
          shapesIconMap[findShape!.name]
        ) : findShape!.name === "Marquise" ? (
          shapesIconMap[findShape!.name]
        ) : (
          <Special title={findShape!.name} className="special-shape-icon" />
        ),
      tooltip: findShape!.name,
    };
  });
  return newShapes;
};

const getSpecialShapes = (state: ApplicationState, pageId?: string): ListFilterItem[] => {
  if (!!pageId && pageId === "/salt&pepper") {
    return state.data.shapes
      .filter(shape => shape?.parentShape?.name === "Special" || shape.name.toLowerCase() === "heart")
      .filter(
        shape =>
          ![
            "old european",
            "old mine",
            "marquise",
            "octagonal modified brilliant",
            "modified kite step cut",
            "circular",
            "baguette",
            "rhomboid",
            "tapered baguette",
            "bullet",
            "trapezoid",
            "briolette",
            "cadillac",
            "flower",
            "star",
            "butterfly",
            "epaulette",
          ].includes(shape.name.toLowerCase())
      )
      .map(shape => ({
        id: shape.id,
        display:
          shape.name.toLowerCase() === "heart" ? shapesIconMap[shape.name] : <Special className="special-shape-icon" />,
        tooltip: shape.name,
      }));
  }

  return state.data.shapes
    .filter(s => s.parentShape?.name === "Special")
    .map(s => ({
      id: s.id,
      display: "",
      tooltip: s.name,
    }));
};

const getClarities = (state: ApplicationState): ListFilterItem[] => state.data.clarities.map(keyValueMap);

const getClaritiesWithFilter = (...values: string[]) => (state: ApplicationState): ListFilterItem[] =>
  state.data.clarities.filter(v => values.includes(v.name)).map(keyValueMap);

const getCuts = (state: ApplicationState, pageId?: string): ListFilterItem[] => {
  if (!!pageId && pageId === "/salt&pepper") {
    return state.data.cuts
      .filter(cut => ["brilliant", "double", "rose", "step"].includes(cut.name.toLowerCase()))
      .map(keyValueMapWithTooltip);
  }
  return state.data.cuts
    .filter(cut => !["brilliant", "double", "rose", "step"].includes(cut.name.toLowerCase()))
    .map(keyValueMapWithTooltip);
};
const getFluors = (state: ApplicationState): ListFilterItem[] => state.data.fluors.map(keyValueMap);

const getLabs = (state: ApplicationState): ListFilterItem[] => state.data.labs.map(keyValueMap);

const getPolishes = (state: ApplicationState): ListFilterItem[] => state.data.polishes.map(keyValueMapWithTooltip);

const getSymmetries = (state: ApplicationState): ListFilterItem[] => state.data.symmetries.map(keyValueMapWithTooltip);

const getFancyIntensities = (state: ApplicationState): ListFilterItem[] =>
  state.data.fancyIntensities.map(i => ({
    id: i.id,
    display: i.name,
  }));

const getColors = (state: ApplicationState): ListFilterItem[] => {
  const items = state.data.colors.map(c => ({
    id: c.id,
    display: c.variation || c.name,
  }));

  return _.orderBy(items, "display", "asc");
};

const getColorsByVariations = (...variations: string[]) => (state: ApplicationState): ListFilterItem[] => {
  const items = state.data.colors
    .filter(c => c.name === "White")
    .filter(c => c.variation && variations.includes(c.variation))
    .map(c => ({
      id: c.id,
      display: c.variation,
    }));

  return _.orderBy(items, "display", "asc");
};

const getColorsFancy = (state: ApplicationState): ListFilterItem[] => {
  const items = state.data.colors
    .filter(c => !["White", "Black", "NA", "S&P", "Rustic", "Ice", "Cognac"].includes(c.name))
    .map(c => ({
      id: c.id,
      display: <img src={colorMaps[c.name]} alt={c.name} className={"diamond-fancy-image"} />,
      tooltip: c.name,
    }));

  return _.orderBy(items, "display", "asc");
};

const getColorsSaltNPepper = (state: ApplicationState): ListFilterItem[] => {
  const items = state.data.colors
    .filter(color => ["rustic", "ice", "cognac", "black", "gray"].includes(color.name.toLowerCase()))
    .map(color => ({
      id: color.id,
      display: color.name,
      tooltip: color.name,
    }));

  return _.orderBy(items, "display", "asc");
};

const getColorsByVariationsAndFancy = (...variations: string[]) => (state: ApplicationState): ListFilterItem[] => {
  const byVariations = state.data.colors
    .filter(c => c.name === "White")
    .filter(c => c.variation && variations.includes(c.variation))
    .map(c => ({
      id: c.id,
      display: c.variation,
    }));
  const byFancyColors = state.data.colors
    .filter(c => !["White", "NA"].includes(c.name))
    .map(c => ({
      id: c.id,
      display: c.name,
    }));
  const items = [...byVariations, ...byFancyColors];
  return _.orderBy(items, "display", "asc");
};

const getColorsWhite = (state: ApplicationState): ListFilterItem[] => {
  const items = state.data.colors
    .filter(c => c.name === "White")
    .map(c => ({
      id: c.id,
      display: c.variation,
    }));

  return _.orderBy(items, "display", "asc");
};

const getProduct = (type: ProductType, id: number, state: ApplicationState): MeleeStone | Diamond | any => {
  if (type === ProductType.Diamond) {
    const diamond = (state.diamonds.all || []).find(d => d.id === id) || null;
    return diamond ? toDiamond(diamond, state.data) : null;
  }

  if (type === ProductType.Melee) {
    const melee = state.melee.find(m => m.id === id) || null;
    return melee ? toMelee(melee) : null;
  }

  if (type === ProductType.NameDiamond) {
    const nyd = state.nameDiamonds.find(m => m.id === id) || null;
    return nyd ? toNameDiamond(nyd) : null;
  }
  return null;
};

const getProductAdmin = (type: ProductType, id: number, state: ApplicationState): MeleeStone | Diamond | NameDiamond | null => {
  if (type === ProductType.Diamond) {
    const diamond = state.admin.diamonds.find(d => d.id === id) || null;
    return diamond ? toDiamond(diamond, state.data) : null;
  }

  if (type === ProductType.Melee) {
    const melee = state.admin.melee.find(m => m.id === id) || null;
    return melee ? toMelee(melee) : null;
  }

  if (type === ProductType.NameDiamond) {
    const nyd = state.admin.nameDiamonds.find(m => m.id === id) || null;
    return nyd ? toNameDiamond(nyd) : null;
  }

  return null;
};

const orderMap = (order: Order, state: ApplicationState, admin: boolean) => ({
  ...order,
  items: order.items.map(item => ({
    id: item.productId,
    description: item.description,
    type: item.productType,
    item: admin
      ? getProductAdmin(item.productType, item.productId, state)
      : getProduct(item.productType, item.productId, state),
    totalPrice: item.totalPrice,
  })),
  user: null,
});
const basketItemsMap = (basketItem: Basket, state: ApplicationState, admin: boolean) => {
  const diamond = state.admin.diamonds.find(item => item.id === basketItem.itemId);
  const holdUserCompany = state.admin.users.find(u => u.id === basketItem.userId)?.addresses[0].company;
  const isMelee = state.admin.melee.find(m => m.id === basketItem.itemId);
  return {
    ...basketItem,
    stockId: diamond?.product.stockId || "Melee",
    isHold:
      (diamond?.product.holdUserId === basketItem.userId && diamond?.product.status === DiamondStatusEnum.Hold) ||
      diamond?.product.hold === holdUserCompany
        ? true
        : false,
    diamond: diamond ? toDiamond(diamond, state.data) : isMelee ? toMeleeForBasket(isMelee!.product) : undefined,
    isMelee: !!isMelee ? true : false,
    user: null,
  };
};

const getAdminOrderById = (id: number, state: ApplicationState): OrderViewModel | null => {
  const order = state.admin.orders.find(o => o.id === id);

  if (!order) {
    return null;
  }

  return {
    ...orderMap(order, state, true),
    user: state.admin.users.find(u => u.id === order.userId) || null,
  };
};

const getAdminOrders = (state: ApplicationState): OrderViewModel[] => {
  const orders = state.admin.orders;

  return orders
    .map(order => orderMap(order, state, true))
    .map(order => ({
      ...order,
      user: state.admin.users.find(u => u.id === order.userId) || null,
    }));
};
const getAdminBasketItems = (state: ApplicationState): BasketViewModel[] => {
  const basketItems = state.admin.basketItems;
  return basketItems
    .map(item => basketItemsMap(item, state, true))
    .map(item => ({
      ...item,
      user: state.admin.users.find(u => u.id === item.userId) || null,
    }));
};

const getOrders = (state: ApplicationState): OrderViewModel[] => {
  if (!state.orders.data) {
    return [];
  }

  return state.orders.data.map(order => orderMap(order, state, false));
};

const getOrderById = (id: number, state: ApplicationState): OrderViewModel | null => {
  const order = state.orders.data?.find(o => o.id === id);

  if (!order) {
    return null;
  }

  return orderMap(order, state, false);
};

export {
  getAdminOrders,
  getAdminOrderById,
  getClarities,
  getClaritiesWithFilter,
  getColors,
  getColorsByVariations,
  getColorsFancy,
  getColorsWhite,
  getCuts,
  getFluors,
  getLabs,
  getOrders,
  getOrderById,
  getPolishes,
  getProduct,
  getMainShapes,
  getSpecialShapes,
  getSymmetries,
  orderMap,
  getFancyIntensities,
  getColorsByVariationsAndFancy,
  getSelectedShapes,
  getAdminBasketItems,
  getColorsSaltNPepper,
};
