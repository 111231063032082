import Axios from 'axios';
import { authorizationHeader } from './apiUtils';
import { ImportLog } from '../models/misc';

const routes = {
  importLogs: '/api/logs/importLogs',
};

export const getAllLogs = async (): Promise<ImportLog[]> => {
  const result = await Axios.get<ImportLog[]>(routes.importLogs, {
    headers: authorizationHeader(),
  });
  return result.data;
};
