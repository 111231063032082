import Axios from "axios";
import { WishListItem } from "../models/cart";
import { authorizationHeader } from "./apiUtils";

const routes = {
  addDiamond: "/api/wishlist/diamond",
  addMelee: "/api/wishlist/melee",
  addNameDiamonds: "/api/wishlist/namediamond",
  addParcel: "/api/wishlist/parcel",
  bulkAddDiamonds: "/api/wishlist/diamond-bulk",
  bulkRemoveDiamonds: "/api/wishlist/bulk-remove-basket-item",
  wishList: "/api/wishlist",
  deleteDiamondFromBasketPage: "/api/wishlist/remove-basket-item",
  addDiamondsFromSet: "/api/wishlist/diamond-set",
  deleteDiamondsSetFromBasket: "/api/wishlist/remove-diamonds-set",
};

const getWishListItems = async (): Promise<WishListItem[]> => {
  const result = await Axios.get<WishListItem[]>(routes.wishList, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const addDiamondToWishList = async (itemId: number): Promise<WishListItem> => {
  const result = await Axios.post(
    routes.addDiamond,
    { itemId },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addNameDiamondsToWishList = async (itemIds: number[]): Promise<WishListItem[]> => {
  const result = await Axios.post(
    routes.addNameDiamonds,
    { itemIds: itemIds },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addDiamondsFromSetToWishList = async (itemIds: number[]): Promise<WishListItem[]> => {
  const result = await Axios.post(
    routes.addDiamondsFromSet,
    itemIds.map(id => ({ itemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const addMeleeToWishList = async (itemId: number, numberOfStones?: number, carats?: number): Promise<WishListItem> => {
  const result = await Axios.post(
    routes.addMelee,
    { itemId, numberOfStones, carats },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};
const addParcelToWishList = async (
  itemId: number,
  numberOfStones?: number,
  ringSize?: number
): Promise<WishListItem> => {
  const result = await Axios.post(
    routes.addParcel,
    { itemId, numberOfStones, ringSize },
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const bulkAddDiamondsToCart = async (itemIds: number[]): Promise<WishListItem[]> => {
  const result = await Axios.post(
    routes.bulkAddDiamonds,
    itemIds.map(id => ({ itemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};
const bulkRemoveDiamondsFromBasket = async (wishedIds: number[]): Promise<WishListItem[]> => {
  const result = await Axios.post(
    routes.bulkRemoveDiamonds,
    wishedIds.map(id => ({ wishedItemId: id })),
    {
      headers: authorizationHeader(),
    }
  );

  return result.data;
};

const deleteWishListItem = async (itemId: number): Promise<void> =>
  await Axios.delete(`${routes.wishList}/${itemId}`, {
    headers: authorizationHeader(),
  });
const deleteWishListItemFromBasket = async (wishedItemId: number): Promise<void> => {
  await Axios.post(
    routes.deleteDiamondFromBasketPage,
    { wishedItemId },
    {
      headers: authorizationHeader(),
    }
  );
};

const deleteDiamondsSetFromBasket = async (itemId: number): Promise<void> =>
  await Axios.delete(`${routes.deleteDiamondsSetFromBasket}/${itemId}`, {
    headers: authorizationHeader(),
  });

export {
  addDiamondToWishList,
  addMeleeToWishList,
  addNameDiamondsToWishList,
  bulkAddDiamondsToCart,
  getWishListItems,
  deleteWishListItem,
  addParcelToWishList,
  deleteWishListItemFromBasket,
  bulkRemoveDiamondsFromBasket,
  addDiamondsFromSetToWishList,
  deleteDiamondsSetFromBasket,
};
export function bulkRemoveDiamondsToWishList(wishedIds: number[]) {
  throw new Error("Function not implemented.");
}
