import { createActionCreator, createReducer } from "deox";
import { Dispatch } from "redux";
import { NameDiamond, Product } from "../models/products";
import * as nameDiamondService from "../services/nameDiamond";

export type State = Product<NameDiamond>[];

const fetchNameDiamonds = Object.assign(
  () => async (dispatch: Dispatch) => {
    try {
      const data = await nameDiamondService.getAll();
      dispatch(fetchNameDiamonds.success(data));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@NAME_DIAMONDS/FETCH", resolve => (nameDiamond: Product<NameDiamond>[]) =>
      resolve(nameDiamond)
    ),
  }
);

const defaultState: State = [];

const reducer = createReducer(defaultState, handleAction => [
  handleAction(fetchNameDiamonds.success, (state, action) => action.payload),
]);

const actions = {
  fetchNameDiamonds,
};

export { actions, reducer };
