import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";

const IdleTimerComponent = () => {
  const onIdle = () => {
    window.location.reload();
  };
  return (
    <div>
      <IdleTimer timeout={27 * 100000} onIdle={onIdle}></IdleTimer>
    </div>
  );
};

export default IdleTimerComponent;
