import { createActionCreator, createReducer } from "deox";
import { produce } from "immer";
import { Dispatch } from "redux";
import { ApplicationState, store } from "./store";

export type State = {
  data: boolean;
};

const newUpdatedNotification = Object.assign(
  (serviceWorkerStatus: boolean) => async (dispatch: Dispatch, getState: () => ApplicationState) => {
    try {
      const newNotificationState = serviceWorkerStatus;

      dispatch(newUpdatedNotification.success(newNotificationState));
    } catch {
      return;
    }
  },
  {
    success: createActionCreator("@@PUSHNOTIFICATION/UPDATED/SUCCESS", resolve => (data: boolean) => resolve(data)),
  }
);

const defaultState: State = {
  data: false,
};

const reducer = createReducer(defaultState, handleAction => [
  handleAction(newUpdatedNotification.success, (state, action) =>
    produce(state, draft => {
      draft.data = action.payload;
    })
  ),
]);

const actions = {
  newUpdatedNotification,
};

export { actions, reducer };
