import Axios from "axios";
import { Order, OrderEmailModel, OrderStatus, UpsertOrder } from "../models/orders";
import { UpsertOrderInvoiceModel } from "../models/products";
import { removeEmptyStrings } from "../utils";
import { authorizationHeader } from "./apiUtils";

const routes = {
  adminOrders: "/api/orders/admin",
  adminOrder: "/api/orders/admin/{id}",
  order: "/api/orders/{id}",
  orders: "/api/orders",
  orderStatus: "/api/orders/{id}/status",
  ordersUser: "/api/orders/currentuser",
  orderCompleted: "/api/orders/orderCompleted",
};

const sentEmail = async (dataObj: UpsertOrderInvoiceModel): Promise<OrderEmailModel> => {
  const result = await Axios.post<OrderEmailModel>(routes.orderCompleted, dataObj, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const changeStatus = async (orderId: number, newStatus: OrderStatus): Promise<void> => {
  const route = routes.orderStatus.replace("{id}", orderId.toString());
  await Axios.patch(
    route,
    { newStatus },
    {
      headers: authorizationHeader(),
    }
  );
};

const createOrder = async (): Promise<Order> => {
  const result = await Axios.post<Order>(routes.orders, null, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const createOrderAdmin = async (order: UpsertOrder): Promise<Order> => {
  const data = removeEmptyStrings(order);

  const result = await Axios.post<Order>(routes.adminOrders, data, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const editOrderAdmin = async (id: number, order: UpsertOrder): Promise<Order> => {
  const data = removeEmptyStrings(order);

  const route = routes.adminOrder.replace("{id}", id.toString());
  const result = await Axios.put<Order>(route, data, {
    headers: authorizationHeader(),
  });

  return result.data;
};

const deleteOrder = async (id: number): Promise<void> => {
  const route = routes.adminOrder.replace("{id}", id.toString());
  await Axios.delete(route, {
    headers: authorizationHeader(),
  });
};

const getAll = async (): Promise<Order[]> => {
  const result = await Axios.get<Order[]>(routes.orders, {
    headers: authorizationHeader(),
  });

  const data = result.data.map(order => ({
    ...order,
    creationDate: new Date(order.creationDate),
    completedDate: order.completedDate ? new Date(order.completedDate) : null,
  }));

  return data;
};

const getAllUserOrders = async (): Promise<Order[]> => {
  const result = await Axios.get<Order[]>(routes.ordersUser, {
    headers: authorizationHeader(),
  });

  const data = result.data.map(order => ({
    ...order,
    creationDate: new Date(order.creationDate),
    completedDate: order.completedDate ? new Date(order.completedDate) : null,
  }));

  return data;
};

export {
  changeStatus,
  createOrder,
  createOrderAdmin,
  deleteOrder,
  editOrderAdmin,
  getAll,
  getAllUserOrders,
  sentEmail,
};
